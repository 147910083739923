/**
 * Created by Raul.
 *
 * Agregar las rutas que se desean cargar, usando la carga asincrona, se deben aregar por modulo
 *
 *  @routesApp Arreglo de rutas de la aplicación.
 */
import {Routes} from '@angular/router';
import {AuthGuardAdmin} from './common.module/auth/auth-guard.admin.service';
import { AuthGuardPortal } from './common.module/auth/auth-guard.portal.service';
import { AuthGuardMcPortal } from './common.module/auth/auth-guard-mc.portal.service';

const routesApp: Routes = [
  {
    path: '',
    loadChildren: './portal.index.module/index.module#IndexModule',
  },
  {
    path: 'bandeja_entrada',
    loadChildren: './portal.formalities.module/portal-formalities.module#CitizenFormalityModule',
     canActivate: [AuthGuardAdmin]
  },
  {
    path: 'formalities',
    loadChildren: './portal-legacy/portal.formalities.module/portal-formalities.module#CitizenFormalityModule',
    //  canActivate: [AuthGuardAdmin]
  },
  {
    path: 'reporte-ciudadano',
    loadChildren: './portal.reporte-ciudadano.module/portal-ciudadano.module#PortalCiudadanoModule',
  },
  {
    path: 'agenda',
    loadChildren: './portal.agenda.module/portal-agenda.module#PortalAgendaModule',
    // canActivate: [AuthGuardAdmin]
  },
  {
    path: 'bpm_tramites',
    loadChildren: './portal.bpm.module/bpm.module#BpmModule',
    // canActivate: [AuthGuardPortal]
  },
  {
    path: 'ver-expediente-electronico',
    loadChildren: './portal.consulta-exp-elec.module/consulta-exp-elec.module#ConsultaExpElecModule',
     canActivate: [AuthGuardAdmin]
  },
  {
    path: 'consulta-pago',
    loadChildren: './portal.consulta-pago.module/consulta-pago.module#ConsultaPagoModule',
    // canActivate: [AuthGuardAdmin]
  },
  {
    path: 'noticias',
    loadChildren: './portal.noticias.module/noticias.module#NoticiasModule'
  },
  {
    path: 'pago',
    loadChildren: './testPago.module/test-pago.module#TestPagoModule'
  },
  {
    path: 'consulta-adeudos',
    loadChildren: './portal.consulta-adeudos.module/consulta-adeudos.module#ConsultaAdeudosModule',
    //canActivate: [AuthGuardAdmin]
  },
  {
    path: 'consulta-resolutivo/:id',
    loadChildren: './portal.consulta-resolutivo/consulta-resolutivo.module#ConsultaResolutivoModule',
    // canActivate: [AuthGuardAdmin]
  },
  {
    path: 'consulta-adeudos-agua',
    loadChildren: './portal.consulta-adeudos-agua.module/consulta-adeudos-agua.module#ConsultaAdeudosAguaModule',
    //canActivate: [AuthGuardAdmin]
  },
  {
    path: 'consulta-resolutivo-externo/:id',
    loadChildren: './portal.consulta-resolutivo/consulta-resolutivo-externo.module#ConsultaResolutivoExternoModule',
    // canActivate: [AuthGuardAdmin]
  },
  {
    path: 'portal_empleados',
    loadChildren:'./portal.portal-empleados.module/portal-empleados.module#PortalEmpleadoModule'
  },
  {
    path: 'portalEmpleados',
    loadChildren: './portal.menu-empleados.module/menuEmpleados.module#PortalEmpleados',
     canActivate: [AuthGuardAdmin]
  },
  {
    path: 'carrusel-galeria',
    loadChildren: './portal.consulta-galeria-eventos/consulta-galeria-eventos.module#ConsultaGaleriaEventosModule',
    // canActivate: [AuthGuardAdmin]
  },
  {
    path: 'detalle-galeria/:id/:nombre/:fecha/:descripcion',
    loadChildren: './portal.consulta-galeria-eventos/detalle-galeria-evento.module#DetalleGaleriaEventoModule',
    // canActivate: [AuthGuardAdmin]
  },
  {
    path: 'recibos_nomina',
    loadChildren: './portal.consulta-recibos-nomina.module/recibos-nomina.module#RecibosNominaModule',
    // canActivate: [AuthGuardAdmin]
  },
  {
    path: 'calendario',
    loadChildren: './portal.calendario-empleados.module/portal/calendarioEmpleados.module#CalendarioEmpleadosModule',
    // canActivate: [AuthGuardAdmin]
  },
  {
    path: 'predial',
    loadChildren: './portal-legacy/portal.predial.module/predial.module#PredialModule'
  },
  {
    path: 'cash/pass',
    loadChildren: './portal-legacy/portal.cash-pass.module/cash-pass.module#CashPassModule'
  },
  {
    path: 'receptioncfdi',
    loadChildren: './portal.receptioncfdi.module/reception-cfdi.module#UserReceptionModule',
    canActivate: [AuthGuardMcPortal]
  },
  {
    path: 'actualizardatosfiscales',
    loadChildren: './portal.actualiza-datos-fact.module/actualiza-datos-fact.module#ActualizaDatosFactModule'
  },
  {
    path: 'nomina-cfdi',
    loadChildren: './portal.nomina-cfdi.module/nomina-cfdi.module#NominaModule',
    canActivate: [AuthGuardMcPortal]
  },
  {
    path: 'traslado',
    loadChildren: './portal-legacy/portal.traslado.module/traslado.module#TrasladoModule'
  },
  {
    path: 'pool/request',
    loadChildren: './portal-legacy/portal.alberca.module/alberca.module#AlbercaModule',
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'pagos',
    loadChildren: './portal-legacy/portal.receipt-payments.module/payments.module#PaymentsModule'
  },
  {
    path: 'contratistas',
    loadChildren: './portal.contractors.module/contractors.module#ContractorsModule'
  },
  {
    path: 'proveedores',
    loadChildren: './portal-legacy/portal.providers.module/providers.module#ProvidersModule'
  },
  {
    path: 'aviso',
    loadChildren: './portal.aviso.pago.module/aviso.pago.module#AvisoPagoModule'
  },
  {
    path: 'donacion',
    loadChildren: './portal.donations.module/donations.module#DonationsModule',
  },
  {
    path: 'statement',
    loadChildren: './portal.rsstatement.module/rsstatement.module#RsstatementModule',
    canActivate: [AuthGuardPortal]
  }
];
export const PortalRoutesModules = [...routesApp];
