import { Component, OnInit, ViewChild, Output, Input, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ToastService } from 'ng-uikit-pro-standard';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'angular-bootstrap-md';
import { MotivosService } from '../service/motivos.service';
import { MotivosModel } from '../service/motivos-rechazo.model';
import { UploadFile, UploadInput, UploadOutput } from '../../typescripts/pro';
import { environment } from '../../../environments/environment';
import { AuthServerProvider } from '../../common.module/auth/auth-jwt.service';
import { isNullOrUndefined } from 'util';
import { ValidatorHelper } from '../../common.module/util/validator.helper';
import { FormalitiesService } from '../../admin.formalities.module/service/formalities.service';
import { FormalitiesConfigModel } from '../../admin.formalities.module/service/formalitiesConfig.model';
import { RequirementsService} from '../service/requirements.service';
import { TramiteModel } from '../service/tramites_model';
import { DocumentoModel } from '../service/documento_model';
import { FlujoService } from 'src/app/admin.bpm.module/configuracion.flujo.component/service/flujo.service';
import {BpmEspecificacionProcesoDetalleModel} from 'src/app/admin.bpm.module/configuracion.flujo.component/service/model/bpm_especificacion_proceso_det.model';


@Component({
  selector: 'app-motivos-detail',
  templateUrl: './motivos-detail.component.html'
})

export class MotivosDetailComponent implements OnInit {

  @Input() public motivo: MotivosModel = <MotivosModel>{};
  @Output() public reload = new EventEmitter();
  public motivoCopiado: MotivosModel = <MotivosModel>{};
  public disEdit = true;
  public disEditClave = true;
  @ViewChild('MotivoDetail') public modal: ModalDirective;
  public tramite: Array<any>;
  @Input() public documento: Array<any>;
  requestForm: FormGroup;
  public acciones: Array<BpmEspecificacionProcesoDetalleModel> = [];
  public flujoSelect: Array<any> = [];

  public filterFlujos: any = {
    elemPorPagina: 100,
    pagina: 1,
    ordenarPor: '_id',
    forma: 'DESC'
  };

  constructor(private toastMsg: ToastService,
    private fb: FormBuilder,
    private auth: AuthServerProvider,
    private motivosService: MotivosService,
    private formalitiesService: FormalitiesService,
    private requirementsService: RequirementsService,
    private flujoService: FlujoService) { 

  }
  
  




  
  // ngOnChanges(changes: SimpleChanges): void {
  //   console.log(this.motivo,'---');
  //   this.requestForm.patchValue({
  //     documento: this.motivo.requirement_id
  //   })
  // }

  ngOnInit() {
    this.requestForm = this.fb.group({
      active: [''],
      clave: ['', Validators.required],
      descripcion: ['', Validators.required],
      tramite: ['', Validators.required],
      documento: ['', Validators.required]
    })
    this.loadTramites();
    //this.loadDocumentos(); 
  } 

  loadTramites(){
    this.formalitiesService.getAllFormalities().then(data => {
      this.tramite = data.map(res => {
        return {
          value: res._id,
          label: res.nombre
        };
      });
    });
  }

  loadDocumentos(id: string) {
    this.flujoService.getFlujoByAdmTramite(id).then(res => {
      console.log("data que viene", res);
      this.flujoService.getFlujoDet(res._id, this.filterFlujos).then(res => {
        console.log("data que viene", res);
        this.acciones = res;
        this.acciones.forEach(rs => {
          if(rs.no_accion == 2) {
            this.flujoSelect = rs.config.frm_requisitos_tramites;
          }          
        })
        console.log("select", this.flujoSelect)
        this.documento = this.flujoSelect.map(rs => {
          return {value: rs.nombre, label: rs.nombre}
        })
      })
    })

    // this.requirementsService.getAllDocumentos(id).then(res =>{
    //   this.documento = res.map(rs => {
    //     return {value: rs._id, label: rs.nombre}
    //   })     
    // })
    // console.log('motivoCopiado: ', this.motivoCopiado)
  }

 

  cDocumentos(){  
    console.log("idTramite:"+ this.motivoCopiado.tramite_id);
    //Combo documentos
    this.flujoService.getFlujoByAdmTramite(this.motivoCopiado.tramite_id).then(res => {
      console.log("data que viene", res);
      this.flujoService.getFlujoDet(res._id, this.filterFlujos).then(res => {
        console.log("data que viene", res);
        this.acciones = res;
        this.acciones.forEach(rs => {
          if(rs.no_accion == 2) {
            this.flujoSelect = rs.config.frm_requisitos_tramites;
          }          
        })
        console.log("select", this.flujoSelect)
        this.documento = this.flujoSelect.map(rs => {
          return {value: rs.nombre, label: rs.nombre}
        })
      })
    })
    
    // this.requirementsService.getAllDocumentos(this.motivoCopiado.tramite_id).then(res =>{
    //   this.documento = res.map(rs => {
    //     return {value: rs._id, label: rs.nombre}
    //   })
    // })
  }

  enableEdition(){
    if(this.disEdit){
      this.disEdit = false;
      this.disEditClave = true;
      this.toastMsg.info('Modo editar activado');
    } else {
      this.disEdit = true;
      this.disEditClave = true;
      this.toastMsg.info('Modo editar desactivado');
    }
  }

  saveMotivoRechazo() {
    if(this.requestForm.valid == false){
      this.toastMsg.info('Favor de llenar todos los campos obligatorios');
      ValidatorHelper.validateAllFormFields(this.requestForm);
      return;
    }
    if(!this.motivoCopiado._id) {
      this.motivosService.saveMotivoRechazo(this.motivoCopiado).then(res => {
        this.reload.emit();
        this.toastMsg.success('Motivo guardado correctamente');
        this.modal.hide();
      }, err => {
        if (err.body && err.body.message)
          this.toastMsg.error(err.body.message);
        else
          this.toastMsg.error('Ocurrio un error al guardar');
      });
    } else {
      //Actualizacion
      this.motivosService.updateMotivoRechazo(this.motivoCopiado).then(res => {
        Object.keys(this.motivoCopiado).forEach((k) => {
          this.motivo[k] = this.motivoCopiado[k];
        });
        this.reload.emit();
        this.toastMsg.success('Motivo actualizado correctamente');
        this.modal.hide();
      }, err => {
        if (err.body && err.body.message)
          this.toastMsg.error(err.body.message);
        else
          this.toastMsg.error('Ocurrio un error al actualizar');
      });
    }
  }

}
