<div class="row align-items-stretch">
  <div class="col-12">
    <div class="card mt-lg-3">
      <div class="card-header unique-color lighten-1 white-text d-flex">
        <div class="mr-auto align-self-center"><strong>Catálogo de Motivos de Rechazo</strong></div>
       
        <a class="btn-floating btn-sm red" (click)="pdf()" mdbTooltip="Descargar pdf" placement="bottom"
           mdbWavesEffect><i class="far fa-file-pdf white-text" aria-hidden="true"></i>
        </a>        
        <a class="btn-floating btn-sm green" (click)="xls()" mdbTooltip="Descargar excel" placement="bottom"
           mdbWavesEffect><i class="far fa-file-excel white-text" aria-hidden="true"></i>
        </a>
        <button class="btn btn-sm btn-danger waves-light" (click)="deleteMotivosRechazo()" mdbWavesEffect>
            <i class="fa fa-trash mr-1" aria-hidden="true"></i>
            Eliminar
        </button>
        <button type="button" class="btn btn-sm btn-deep-orange waves-light"
                (click)="createModal(detailModal)" mdbWavesEffect>
          <i class="fa fa-plus mr-1" aria-hidden="true"></i>
          Nuevo
        </button>
      </div>
      <div class="card-body table-responsive">
        <table class="table table-sm table-hover">
          <thead>
            <tr>
              <th></th>
              <th class="align-middle text-center font-weight-bold">Clave</th>
              <th class="align-middle text-center font-weight-bold">Descripción</th>
              <th class="align-middle text-center font-weight-bold">Trámite</th>
              <th class="align-middle text-center font-weight-bold">Documento</th>
              <th class="align-middle text-center font-weight-bold">Ver</th>
            </tr>
            <tr>
              <th></th>
              <th>
                <input type="text" id="search_clave" [(ngModel)]="search_clave" class="form-control" mdbActive>
              </th>
              <th>
                <input type="text" id="search_descripcion" [(ngModel)]="search_descripcion" class="form-control" mdbActive>
              </th>
              <th>
                <input type="text" id="search_tramite" [(ngModel)]="search_tramite" class="form-control" mdbActive>
              </th>
              <th>
                <input type="text" id="search_documento" [(ngModel)]="search_documento" class="form-control" mdbActive>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let item of motivosList | filterBy: {clave: search_clave} 
              | filterBy: {descripcion: search_descripcion} | filterBy: {tramite: search_tramite}
              | filterBy: {nombre_documento: search_documento}; let i = index;">
              <td class="align-middle text-center">
                <div class="form-check mt-1">
                  <input class="form-check-input" mdbInputDirective type="checkbox" [(ngModel)]="item.selected"
                        id="checkbox{{i}}">
                  <label class="form-check-label" for="checkbox{{i}}"></label>
                </div>
              </td>
              <td class="align-middle text-center">{{item.clave}}</td>
              <td class="align-middle text-center">{{item.descripcion}}</td>
              <td class="align-middle text-center">{{item.tramite[0].nombre}}</td>
              <td class="align-middle text-center">{{item.nombre_documento}}</td>
              <td class="align-middle text-center">
                <button type="button" class="btn btn-sm blue waves-light"
                        (click)="detail(detailModal, item);" mdbWavesEffect>
                  <i class="fa fa-binoculars white-text" aria-hidden="true"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<app-motivos-detail #detailModal [motivo]="motivoRechazo" (reload)="loadMotivosRechazo()"></app-motivos-detail>