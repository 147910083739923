/**
 * Created by Raul.
 *
 * Agregar las rutas que se desean cargar, usando la carga asincrona, se deben aregar por componente.
 *
 *  @routesApp Arreglo de rutas de la aplicación.
 */
import {Routes} from '@angular/router';
import {AuthGuardAdmin} from './common.module/auth/auth-guard.admin.service';
import {IndexAdminComponent} from './main-admin.component/index-admin.component';
import {AuthGuardChildren} from './common.module/auth/auth-guard-children';


const routesApp: Routes = [
  {
    path: '',
    component: IndexAdminComponent,
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'log-users',
    loadChildren: './admin.log-users.module/log-users.module#LogUsersModule',
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'adm_roles',
    loadChildren: './admin.roles.module/admin-roles.module#AdminRolesModule',
    data: {
      pageTitle: 'Administración de Roles'
    },
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'adm_usuarios',
    loadChildren: './admin.admin.module/admin-admin.module#AdminAdminModule',
    data: {
      pageTitle: 'Administración de Usuarios'
    },
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'tramites',
    loadChildren: './admin.formalities.module/admin-formalities.module#AdminFormalitiesModule',
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'bandeja_entrada',
    loadChildren: './admin.index.module/admin-index.module#AdminIndexModule',
    data: {
      pageTitle: 'Bandeja de entrada'
    },
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'cancelar_pase_caja',
    loadChildren: './admin.cancelarpasescaja.module/cancelarpasescaja.module#CancelarpasescajaModule',
    data: {
      pageTitle: 'Cancelar pases de caja'
    },
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'adm_areas',
    loadChildren: './admin.areas.module/admin-areas.module#AdminAreasModule',
    data: {
      pageTitle: 'Áreas'
    },
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'catalogo_categorias',
    loadChildren: './admin.categorias.module/categorias.module#CategoriasModule',
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'catalogo_tramites',
    loadChildren: './admin.tramites.module/admin-tramites.module#AdminTramitesModule',
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'catalogo_etiquetas',
    loadChildren: './admin.tramites.module/admin-tramites.module#AdminTramitesModule',
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'cnf-folio',
    loadChildren: './admin.cnf-folio.module/cnf-folio.module#CnfFolioModule',
    data: {
      pageTitle: 'Folios'
    },
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'admin-agenda',
    loadChildren: './admin.agenda.module/admin-agenda.module#AppAgendaModule',
    // canActivate: [AuthGuardAdmin]
  },
  {
    path: 'configuracion_rc',
    loadChildren: './admin.configuracion-rc.module/admin.configuracion-rc.module#AdminConfiguracionRcModule',
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'configuracion_rda',
    loadChildren: './admin.configuracion-rda.module/admin.configuracion-rda.module#AdminConfiguracionRdaModule',
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'reporte-ciudadano',
    loadChildren: './admin.reporte-ciudadano.module/admin-reporte-ciudadano.module#AdminReporteCiudadanoModule',
    // canActivate: [AuthGuardAdmin]
  },
  {
    path: 'expediente-electronico',
    loadChildren: './admin.expediente-electronico.module/expediente-electronico.module#ExpedienteElectronicoModule',
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'bpm_rcs_tramites',
    loadChildren: './admin.bpm.module/adm.bpm.module#AdmBpmModule',
    canActivateChild: [AuthGuardChildren]
  },
  {
    path: 'bpm_vu_tramites',
    loadChildren: './admin.bpm.vu.module/adm.bpm.module#AdmBpmVuModule',
    canActivateChild: [AuthGuardChildren]
  },
  {
    path: 'bpm_ti_tramites',
    loadChildren: './admin.bpm.tram-info.module/adm.bpm.tram-info.module#AdmBpmTIModule',
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'bpm_tramites',
    loadChildren: './admin.bpmflujo.module/adm.bpmflujo.module#AdmBpmFlujoModule',
    // canActivate: [AuthGuardAdmin]
  },
  {
    path: 'ficha-tecnica',
    loadChildren: './admin.ficha-tecnica.module/ficha-tecnica.module#FichaTecnicaModule',
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'ver-expediente-electronico',
    loadChildren: './admin.consulta-exp-elec.module/consulta-exp-elec.module#AdminConsultaExpElecModule',
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'configuracion_chat',
    loadChildren: './admin.configuracion-chat.module/configuracion-chat.module#ConfiguracionChatModule',
    data: {
      pageTitle: 'Configuración del Chat'
    },
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'evaluacion-ciudadana',
    loadChildren: './admin.evaluacion-ciudadana.module/evaluacion-ciudadana.module#EvaluacionCiudadanaModule',
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'banners',
    loadChildren: './admin.banners.module/banners.module#BannersModule',
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'noticias',
    loadChildren: './admin.noticias.module/noticias.module#NoticiasModule',
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'catalogo_zonas',
    loadChildren: './admin.zonas.module/zona.module#ZonaModule',
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'configuracion-formularios',
    loadChildren: './admin.configuracion-formulario.module/configuracion-formulario.module#ConfiguracionFormularioModule',
    canActivate: [AuthGuardAdmin]
  },
  /*{
    path: 'catalogo_documentos',
    loadChildren: './admin.documentos.module/documentos.module#DocumentosModule',
    canActivate: [AuthGuardAdmin]
  },*/
  {
    path: 'carga_masiva',
    loadChildren: './admin.carga-masiva.module/carga-masiva.module#CargaMasivaModule',
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'carga_masiva_predios',
    loadChildren: './admin.carga-masiva-predios.module/carga-masiva-predios.module#CargaMasivaPrediosModule'
  },
  {
    path: 'consolidacion_pagos',
    loadChildren: './admin.consolidacion-pagos.module/consolidacion-pagos.module#ConsolidacionPagosModule'
    // canActivate: [AuthGuardAdmin]
  },
  {
    path: 'configuracion_general',
    loadChildren: './admin.configuracion-general.module/configuracion-general.module#ConfiguracionGeneralModule',
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'bitacora',
    loadChildren: './admin.bitacora.module/bitacora.module#BitacoraModule',
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'bitacora-ficha-tecnica',
    loadChildren: './admin.bitacora.fichas-tecnicas.module/bitacora-ficha-tecnica.module#FichaTecnicaBitacoraModule',
    // canActivate: [AuthGuardAdmin]
  },
  {
    path: 'visor',
    loadChildren: './admin.visor.module/visor.module#VisorModule',
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'reportes',
    loadChildren: './admin.reportes.module/reportes.module#ReportesModule',
    // canActivate: [AuthGuardAdmin] //FIXME descomentar
  },
  {
    path: 'actualiza_datadic',
    loadChildren: './admin.act-datos-adic.module/actualiza_datosadic.module#Actualiza_DatosAdicModule',
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'galeria_eventos',
    loadChildren: './admin.eventos.empleados.module/eventos_empleados.module#Eventos_EmpleadosModule',
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'configura_tramite_vacaciones',
    loadChildren: './admin.config.tram-vac.module/configura_tramvac.module#Configura_TramVacModule',
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'configuracion_correo',
    loadChildren: './admin.configuracion-correo.module/configuracion-correo.module#ConfiguracionCorreoModule',
  },
  {
    path: 'dashboard',
    loadChildren: './admin.dashboard.module/dashboard.module#DashboardModule',
    // canActivate: [AuthGuardAdmin]
  },
  {
    path: 'configuracion_mapa_calor',
    loadChildren: './admin.configuracion-mapa-calor.module/configuracion-mapa-calor.module#ConfiguracionMapaCalorModule',
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'configuracion_nueva_accion',
    loadChildren: './admin.configuracion-accion.module/configuracion-accion.module#ConfiguracionAccionModule',
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'adeudos',
    loadChildren: './admin.adeudos.module/admin.adeudos.module#AdminAdeudosModule',
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'cat-resolutivos-plant',
    loadChildren: './admin.resolutivo-plant-html.module/resolutivo-plant-html.module#ResolutivoPlantHtmlModule',
    //canActivate: [AuthGuardAdmin]
  },
  {
    path: 'catalogo-formatos-solicitud',
    loadChildren: './admin.formatos-solicitud-html.module/formatos-solicitud-html.module#FormatosSolicitudHtmlModule',
    //canActivate: [AuthGuardAdmin]
  },
  {
    path: 'catalogo_resolutivos',
    loadChildren: './admin.resolutivosplantillas.module/plantresol.module#PlantResolModule',
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'catalogo_seccionresolutivos',
    loadChildren: './admin.seccionresolutivosplantillas.module/plantresolsecc.module#PlantResolSeccModule',
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'catalogo_etiqresolutivos',
    loadChildren: './admin.etiqresolutivosplantillas.module/plantresoletiq.module#PlantResolEtiqModule',
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'adm_organizaciones',
    loadChildren: './admin.organizaciones.module/organizaciones.module#AdminOrganizacionesModule',
    data: {
      pageTitle: 'Organizaciones'
    },
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'portal_empleados',
    loadChildren: './admin.portal-empleados-module/portal-empleados.module#PortalEmpleadoModule'
  },
  {
    path: 'tramitesMc',
    loadChildren: './portal-legacy/admin.index.module/admin.index.module#AdminIndexModule',
    // canActivate: [AuthGuardAdmin]
  },
  {
    path: 'pool',
    loadChildren: './portal-legacy/admin.albercas.module/albercas.module#AlbercasModule',
    // canActivate: [AuthGuardAdmin]
  },
  {
    path: 'contratistas',
    loadChildren: './admin.contractors.module/admin-contractors.module#AdminContractorsModule',
    // canActivate: [AuthGuardAdmin]
  },
  {
    path: 'control-cfdi',
    loadChildren: './admin.cfdi.module/cfdi.module#CfdiModule',
    // canActivate: [AuthGuardAdmin]
  },
  {
    path: 'act-datos-fiscales',
    loadChildren: './admin.actualiza-datos-fact.module/actualiza-datos-fact.module#ActualizaDatosFactModule',
    // canActivate: [AuthGuardAdmin]
  },
  {
    path: 'pagos',
    loadChildren: './admin.receipt-payments.module/payments.module#PaymentsModule',
    // canActivate: [AuthGuardAdmin]
  },
  {
    path: 'nomina-cfdi',
    loadChildren: './admin.nomina-cfdi.module/nomina-cfdi.module#NominaModule',
    // canActivate: [AuthGuardAdmin]
  },
  {
    path: 'recepcion',
    loadChildren: './portal-legacy/admin.receptioncfdi.module/reception-cfdi.module#ReceptionModule',
    // canActivate: [AuthGuardAdmin]
  },
  {
    path: 'modificar_acciones',
    loadChildren: './admin.mod.acciones.module/acciones.module#AccionesModule',
    // canActivate: [AuthGuardAdmin] //FIXME descomentar
  },
  {
    path: 'menuEmpleados',
    loadChildren: './admin.menu-empleados.module/menuEmpleados.module#MenuEmpleados'
  },
  {
    path: 'calendario',
    loadChildren: './portal.calendario-empleados.module/admin/calendario-configuracion.module#CalendarioConfiguracionModule'
  },
  {
    path: 'portal_empleados',
    loadChildren: './admin.portal-empleados-module/portal-empleados.module#PortalEmpleadoModule'
  },
  {
      path: 'donacion',
      loadChildren: './admin.donations.module/admin-donations.module#AdminDonationsModule',
      canActivate: [AuthGuardAdmin]
  },
  {
    path: 'proveedores',
    loadChildren: './portal-legacy/admin.providers.module/admin-providers.module#AdminProvidersModule',
    // canActivate: [AuthGuardAdmin]
  },
  {
    path: 'traslado',
    loadChildren: './portal-legacy/admin-fiscalizacion.module/fiscalizacion.module#FiscalizacionModule',
    // canActivate: [AuthGuardAdmin]
  },
  {
    path: 'traslados',
    loadChildren: './portal-legacy/admin.traslado.module/traslado.module#TransferListModule',
    // canActivate: [AuthGuardAdmin]
  },
  {
    path: 'transfer',
    loadChildren: './admin.transfer.module/transfer.module#TransferModule',
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'consultas/traslado',
    loadChildren: './portal-legacy/admin.tdd-consult.module/tdd-consult.module#TddConsultModule',
    // canActivate: [AuthGuardAdmin]
  },
  {
    path: 'realstate',
    loadChildren: './portal-legacy/admin.realstate.module/admin-realstate.module#AdminRealstateModule',
    //canActivate: [AuthGuardAdmin]
  },
  {
    path: 'cajaCODI',
    loadChildren: './portal-legacy/admin.cajaCODI.module/cajaCODI.module#CODIModule',
    //canActivate: [AuthGuardAdmin]
  },
  {
    path: 'vobo',
    loadChildren: './admin.vobo.module/admin-vobo.module#VoBoModule',
    // canActivate: [AuthGuardAdmin]
  },
  {
    path: 'consulta-pagos',
    loadChildren: './admin.payments.module/admin-payment.module#PaymentsModule',
   // canActivate: [AuthGuardAdmin]
  },
  {
    path: 'motivos_rechazo',
    loadChildren: './admin.motivos-rechazo.module/motivos-rechazo.module#MotivosRechazoModule',
   // canActivate: [AuthGuardAdmin]
  }
];

export const AdminRoutesModules = [...routesApp];
