import {Injectable} from '@angular/core';
import {IResourceMethod, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';
import {BpmEspecificacionProcesoMasterModel} from '../model/bpm_especificacion_proceso_master.model';
import {TramitesModel} from '../model/tramites.model';
import {SeccionesTramitesModel} from '../model/secciones.model';
import {TipoCampo} from '../model/bpm_tipo_campo';
import {BpmEntidadModel} from '../model/bpm-entidad.model';
import {ExpRequisitoModel} from '../model/expediente_requisito.model';
import {BpmEspecificacionProcesoDetalleModel} from '../model/bpm_especificacion_proceso_det.model';
import {ResourceAuth} from '../../../../common.module/auth/authenticate.resource';
import {AuthServerProvider} from '../../../../common.module/auth/auth-jwt.service';


@Injectable()
@ResourceParams({
  pathPrefix: '/ven-unica'
  // url: 'http://localhost:8006'
})

 export class FlujoResource extends ResourceAuth {
// export class FlujoResource extends Resource {

  constructor(restHandler: ResourceHandler, provService: AuthServerProvider) {
    super(restHandler, provService);
  }

//  constructor(restHandler: ResourceHandler) {
  //  super(restHandler);
//  }
  @ResourceAction({
    method: ResourceRequestMethod.Get,
    isArray: true,
    path: '/bpm-especificacion-proceso-master'
  })
  getFlujos: IResourceMethod<any, BpmEspecificacionProcesoMasterModel[]>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    isArray: false,
    path: '/bpm-especificacion-proceso-master'
  })
  insertFlujo: IResourceMethod<any, BpmEspecificacionProcesoMasterModel>;

  @ResourceAction({
    path: '/bpm-especificacion-proceso-master/{!id}',
    method: ResourceRequestMethod.Get
  })
  getFlujo: IResourceMethod<{ id: any }, BpmEspecificacionProcesoMasterModel>;

  @ResourceAction({
    path: '/bpm-especificacion-proceso-det?bpm_especificacion_proceso_master_id={!id}',
    method: ResourceRequestMethod.Get
  })
  getFlujoDet: IResourceMethod<{ id: any }, BpmEspecificacionProcesoDetalleModel>;

  @ResourceAction({
    path: '/bpm-especificacion-master-by-adm-tramite-id/{!id}',
    method: ResourceRequestMethod.Get
  })
  getFlujoByAdmTramite: IResourceMethod<{ id: any }, BpmEspecificacionProcesoMasterModel>;

  @ResourceAction({
    method: ResourceRequestMethod.Patch,
    path: '/bpm-especificacion-proceso-master'
  })
  updateFlujo: IResourceMethod<BpmEspecificacionProcesoMasterModel, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Delete,
    path: '/bpm-especificacion-proceso-master/{!id}'
  })
  deleteFlujo: IResourceMethod<{id: any}, any>;

  @ResourceAction({
    path: '/bpm-tramites',
    method: ResourceRequestMethod.Get
  })
  obtTramites: IResourceMethod<any, TramitesModel>;

  @ResourceAction({
    path: '/secciones',
    method: ResourceRequestMethod.Get
  })
  obtSecciones: IResourceMethod<any, SeccionesTramitesModel[]>;
  @ResourceAction({
    path: '/lstcampo',
    method: ResourceRequestMethod.Get
  })
  obtTipoCampo: IResourceMethod<any, TipoCampo>;

  @ResourceAction({
    path: '/bpm-entidad?estatus=true&elemPorPagina=30',
    method: ResourceRequestMethod.Get
  })
  obtEntidades: IResourceMethod<any, BpmEntidadModel[]>;

  @ResourceAction({
    path: '/requisito',
    method: ResourceRequestMethod.Get
  })
  obtExpedienteReq: IResourceMethod<any, ExpRequisitoModel>;


  @ResourceAction({
    path: '/bpm-especificacion-proceso-det/{!id}',
    method: ResourceRequestMethod.Delete
  })
  dltDetalle: IResourceMethod<{id: any}, any>;
}
