/**
 * Created by Luis on 13/08/2018.
 */
import {Injectable} from '@angular/core';
import {IResourceAction, IResourceMethod, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';
import {RequestsModel} from './requests.model';
import {RequestModel} from './request.model';
import {StatusModel} from './status.model';
import {TimeModel} from './time.model';
import {States} from './states.model';
import {Towns} from './towns.model';
import {DataCamCols} from './data-camcols';
import {DocumentsUsrModel} from './documents-usr.model';

import {ObservationsModel} from './observation.model';
import {PaymentRequestResponseModel} from './payment-request-response.model';
import {RequestInsertModel} from './request-insert.model';
import {ResponseCashModel} from './response-cash.model';
import {ValidRfcModel} from './valid-rfc.model';
import {Suburbs} from './suburbs.model';
import {DocumentsModel} from './documents.model';
import {HistoryModel} from './history.model';
import {BestowalPdfModel} from './bestowal-pdf.model';
import {BestowalSavePdfModel} from './bestowal-save-pdf';
import {DownloadDocument} from './download-document.model';

import {AuthServerProvider} from "../auth/auth-jwt.service";
import {ResourceAuth} from "../auth/authenticate.resource";
import {DatosColListModel} from "./datos-col-list.model";
import { AuthCorregidoraService } from '../auth/auth-corregidora.service';

@Injectable()
@ResourceParams({
  // url: 'http://localhost:8075'
   pathPrefix: '/contractors'
})
/**
 export class RequestsResource extends Resource {
   constructor(resHandler: ResourceHandler) {
     super(resHandler);
   }*/

export class RequestsResource extends ResourceAuth {
// export class RequestsResource extends AuthCorregidoraService {
  constructor(restHandler: ResourceHandler, contService: AuthServerProvider) {
    super(restHandler, contService);
  }

  @ResourceAction({
    array: true,
    path: '/valid-rfc'
  })
  getValidRfc: IResourceMethod<{}, ValidRfcModel>;

  @ResourceAction({
    isArray: true,
    path: '/requests',
    method: ResourceRequestMethod.Get
  })
  getRequests: IResourceMethod<any, RequestsModel>;

  @ResourceAction({
    isArray: true,
    path: '/request/{!pkey}',
    method: ResourceRequestMethod.Get
  })
  getRequest: IResourceMethod<{ pkey: any }, RequestModel>;

  @ResourceAction({
    isArray: true,
    path: '/update-request/{!pkey}',
    method: ResourceRequestMethod.Get
  })
  getUpdateRequest: IResourceMethod<{ pkey: any }, RequestModel>;

  @ResourceAction({
    method: ResourceRequestMethod.Put,
    path: '/status'
  })
  updateStatus: IResourceMethod<StatusModel, any>;

  @ResourceAction({
    isArray: true,
    path: '/stage',
    method: ResourceRequestMethod.Get
  })
  getLapses: IResourceMethod<any, TimeModel>;
  @ResourceAction({
    path: '/requests-user',
    method: ResourceRequestMethod.Get
  })
  getRequestUser: IResourceMethod<any, RequestsModel>;

  @ResourceAction({
    path: '/obras',
    method: ResourceRequestMethod.Get
  })
  getObras: IResourceMethod<any, DatosColListModel>;
  @ResourceAction({
    path: '/documents-category?pTipoPersona={!tipoPer}&pTipoSolicitud={!tipoSol}',
    method: ResourceRequestMethod.Get
  })
  getDocumentos: IResourceMethod<{ tipoPer: any, tipoSol: any }, DocumentsUsrModel>;

  @ResourceAction({
    isArray: true,
    path: '/camaras?pTipo={!pTipo}',
    method: ResourceRequestMethod.Get
  })
  getCamCols: IResourceMethod<{ pTipo: any }, DataCamCols>;

  @ResourceAction({
    isArray: true,
    path: '/comments-status?pkey={!pkey}',
    method: ResourceRequestMethod.Get
  })
  getObservationsRefuse: IResourceMethod<{ pkey: any }, ObservationsModel>;

  @ResourceAction({
    isArray: true,
    path: '/payment-request/{!pcgk}',
    method: ResourceRequestMethod.Get
  })
  getPaymentRequest: IResourceMethod<{ pcgk: any }, PaymentRequestResponseModel>;

  @ResourceAction({
    isArray: true,
    path: '/request-insert',
    method: ResourceRequestMethod.Post
  })
  postRequest: IResourceMethod<RequestInsertModel, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Put,
    path: '/update/make-payment'
  })
  updateMakePayment: IResourceMethod<BestowalSavePdfModel, any>;

  @ResourceAction({
    isArray: false,
    path: '/na/getStates'
  })
  getStates: IResourceMethod<any, States>;

  @ResourceAction({
    isArray: false,
    path: '/na/getTowns'
  })
  getTowns: IResourceMethod<any, Towns>;

  @ResourceAction({
    isArray: false,
    path: '/na/getSuburbs'
  })
  getSuburbs: IResourceMethod<any, Suburbs>;

  @ResourceAction({
    isArray: true,
    path: '/update/requests-user',
    method: ResourceRequestMethod.Get
  })
  getUpdateRequests: IResourceMethod<any, RequestsModel>;

  @ResourceAction({
    method: ResourceRequestMethod.Put,
    path: '/update/request'
  })
  updateRequest: IResourceMethod<RequestInsertModel, any>;

  @ResourceAction({
    isArray: true,
    path: '/delete-doc/{!pdoctok}',
    method: ResourceRequestMethod.Delete
  })
  delete: IResourceMethod<any, DocumentsModel[]>;

  @ResourceAction({
    isArray: true,
    path: '/update/request-insert',
    method: ResourceRequestMethod.Post
  })
  postRequestUpdate: IResourceMethod<RequestInsertModel, any>;

  @ResourceAction({
    isArray: true,
    path: '/status/{!pkey}',
    method: ResourceRequestMethod.Get
  })
  getStatusHistory: IResourceMethod<{ pkey: any }, HistoryModel>;

  @ResourceAction({
    isArray: true,
    path: '/na/pdf-otorgamiento?pKey={!pKey}',
    method: ResourceRequestMethod.Get
  })
  getRepOtorgamiento: IResourceMethod<{ pKey: any }, BestowalPdfModel>;

  @ResourceAction({
    isArray: true,
    path: '/save-document',
    method: ResourceRequestMethod.Post
  })
  saveDocument: IResourceMethod<DocumentsModel, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/download-document/{!id}',
  })
  DownloadDocument: IResourceMethod<{id: any}, DownloadDocument>;
}

