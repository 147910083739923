import { Component, OnInit, ViewChild } from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
import { ExcelService } from '../../common.module/util/exportxls.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { MotivosService } from '../service/motivos.service';
import { MotivosDetailComponent } from '../motivos-detail/motivos-detail.component';
import { MotivosModel } from '../service/motivos-rechazo.model';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-motivos-list',
  templateUrl: './motivos-list.component.html'
})
export class MotivosListComponent implements OnInit {

  @ViewChild(MotivosDetailComponent) modal;
  public motivosList: MotivosModel[] = [];
  public excellevel: MotivosModel[];
  public dataPdf: any;
  public motivoRechazo: MotivosModel = <MotivosModel>{};
  public search_clave: any;
  public search_descripcion: any;
  public search_tramite: string;
  public search_documento: any;
  public idTramite: number;
  public documento: Array<any>;
  public requirement_id: any;

  constructor(private excel: ExcelService,
    private toastMsg: ToastService,
    private motivosService: MotivosService) { 
  }

  
  ngOnInit() {
    this.loadMotivosRechazo();
  }

  loadMotivosRechazo() {
    this.motivosService.getAllMotivosRechazo().then(data => {
      this.motivosList = data;
      console.log('todos los motivos: ', this.motivosList);
    }, err => {
      if (err.body && err.body.message)
        this.toastMsg.error(err.body.message);
      else
        this.toastMsg.error('Ha ocurrido un error al obtener los motivos de rechazo');
    });
  }

  pdf() {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const tmpData: any[] = [
      [{text: 'Id', style: 'header'},
        {text: 'Clave', style: 'header'},
        {text: 'Descripcion', style: 'header'},
        {text: 'Tramite', style: 'header'},
        {text: 'Documento', style: 'header'},
      ]
    ];

    const result = this.motivosList;
    result.forEach(itm => {
      itm._id = isNullOrUndefined(itm._id) ? "" : itm._id;
      itm.clave = isNullOrUndefined(itm.clave) ? '' : itm.clave;
      itm.descripcion = isNullOrUndefined(itm.descripcion) ? '' : itm.descripcion;
      itm.nombre_tramite = isNullOrUndefined(itm.tramite[0].nombre) ? '' : itm.tramite[0].nombre;
      itm.nombre_documento = isNullOrUndefined(itm.nombre_documento) ? '' : itm.nombre_documento;
      tmpData.push([
        {text: itm._id, style: 'anotherStyle'},
        {text: itm.clave, style: 'anotherStyle'},
        {text: itm.descripcion, style: 'anotherStyle'},
        {text: itm.nombre_tramite, style: 'anotherStyle'},
        {text: itm.nombre_documento, style: 'anotherStyle'}
      ]);
    });

    this.dataPdf = {
      border: [false, true, false, false],
      fontSize: 8,
      pageOrientation: 'landscape',
      content: [
        {
          table: {
            body: tmpData
          },
          layout: {
            fillColor(rowIndex, node, colIndex) {
              return rowIndex % 2 === 0 ? '#e9e9e9' : '#fff';
            }
          }
        }
      ],
      styles: {
        header: {
          fontSize: 12,
          bold: true,
          alignment: 'center',
          fillColor: '#3F729B',
          color: '#ffffff'
        },
        anotherStyle: {
          fontSize: 10,
          italic: true,
          alignment: 'left'
        }
      }
    };
    console.log(this.dataPdf);
    console.log(this.dataPdf.content[0].table.body.length);
    if (this.dataPdf.content[0].table.body.length > 1) {
      pdfMake.createPdf(this.dataPdf).download('MotivosRechazo.pdf');
    } else {
      this.toastMsg.error('No se puede generar el PDF, no hay datos');
    }
  }

  xls() {
    this.excellevel = this.motivosList;
    const result = this.excellevel.map(obj => {
      return <MotivosModel>{
        Id: obj._id,
        Clave: obj.clave,
        Descripcion: obj.descripcion,
        Tramite: obj.tramite[0].nombre,
        Documento: obj.nombre_documento
      };
    });
    // console.info(result.length);
    if (result.length > 0) {
      this.excel.exportAsExcelFile(result, 'MotivosRechazo');
    } else {
      this.toastMsg.error('No se puede generar el XLS, no hay datos');
    }
  }

  detail(detailModal, item: MotivosModel) {
    console.log('Detalle:' + item._id);
    console.log('idTramite: ' + item.tramite_id);
    console.log('idDocumento: ' + item.documento_id);
    if (!isNullOrUndefined(item._id)) {
        this.modal.disEdit = true;
        setTimeout(() => {
          this.modal.motivoCopiado =  this.copyItem(item);
        }, 500)
        // this.modal.motivoCopiado = this.copyItem(item);
       // this.motivoRechazo = item;  
        this.modal.loadDocumentos(item.tramite_id);  
    } 
    detailModal.modal.show();
  }

  // Método que copia un elemento de tipo requerimiento
  copyItem(item: MotivosModel) {
    const copiedObj = <MotivosModel>{};
    Object.keys(item).forEach((k) => {
      copiedObj[k] = item[k];
    });
    return copiedObj;
  }

  createModal(create) {
    this.modal.documento = undefined;
    this.motivoRechazo = <MotivosModel>{};
    create.modal.show();
    this.modal.disEdit = false;
    this.modal.disEditClave = false;
    this.modal.motivoCopiado = <MotivosModel>{};
  }

  deleteMotivosRechazo() {
    const listToDelete = [];
    this.motivosList.forEach(itm => {
      if (itm.selected === true) {
        listToDelete.push(itm._id);
      }
    });
    if (listToDelete.length > 0) {
      const stackPromise: Promise<any>[] = [];
      listToDelete.forEach(item => {
        stackPromise.push(this.motivosService.deleteMotivoRechazo(item));
        setTimeout(() => {
          this.loadMotivosRechazo();
        }, 1000);
      });
      Promise.all(stackPromise).then(res => {
        this.toastMsg.success('Se ha eliminado satisfactoriamente');

      }, error => {
        if (error.body && error.body.message)
          this.toastMsg.error(error.body.message);
        else
          this.toastMsg.error('Algunos elementos no fuerón eliminados');
      });
    } else {
      this.toastMsg.error('Debes elegir al menos un registro para borrar');
    }
  }
}
