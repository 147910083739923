/**
 * Created by Raul on 15/05/2018.
 */

import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UsuarioModel} from '../login.component/service/usuario.model';
import {UsuarioService} from '../login.component/service/usuario.service';
import {isNullOrUndefined} from 'util';
import {GeneralPatternsValidators} from '../../common.module/util/general.patterns.validator';
import {ModalDirective, ToastService, UploadFile, UploadInput, UploadOutput} from "ng-uikit-pro-standard";
import {ValidatorHelper} from "../../common.module/util/validator.helper";
import { environment } from 'src/environments/environment';


@Component({

  selector: 'app-data-user',
  templateUrl: 'datos-usuario.component.html',
  
})
export class DatosUsuarioComponent implements OnInit {
  @ViewChild('formDataUser')
  public modal: ModalDirective;
  public formUser: FormGroup;

  public twoFactorAuth: boolean = false;
  public file: UploadFile;
  public fileB64: UploadFile;

  public uploadInput = new EventEmitter<UploadInput>();
  public imgBase64: any;
  public validarB64 = false;
  public imagenCargada:any;

  public user: UsuarioModel = <UsuarioModel> {
    authorities: []
  };

  constructor(private fb: FormBuilder,
              private userService: UsuarioService,
              private toastMsg: ToastService) {
  }

  ngOnInit() {
    // Validators login
    console.log(this.user, 'user data--------------')
   
    this.formUser = this.fb.group({
      defaultFormName: ['',Validators.required],
      defaultFormFirstName: ['',Validators.required],
      defaultFormLastName: ['',Validators.required],
      defaultFormEmail: ['',],
      defaultRFC: ['', Validators.compose([Validators.minLength(12), Validators.maxLength(13),
        Validators.pattern(GeneralPatternsValidators.RFC_PATTERN)])],
      defaultCURP: ['', Validators.pattern(GeneralPatternsValidators.CURP_PATTERN)],
      dafaultPhone: ['', Validators.compose([Validators.maxLength(10)])],
      defaultCellphone:['', Validators.compose([Validators.maxLength(10)])],
      contrasenia: [''],
      contraseniaConfirma: [''],
      twoFactorAuth:[],
    });
    this.formUser.controls['defaultFormEmail'].disable();
  }

  loadUser() {
    console.log('SE EJECUTA loadUser()')
    this.userService.obtenerUsuarioEnSesion().then(res => {
      console.log(res, 'userService.obtenerUsuarioEnSesion')
      this.formUser.patchValue({
        defaultFormName: res.nombre,
        defaultFormFirstName: res.apellido_m,
        defaultFormLastName: res.apellido_p,
        defaultFormEmail: res.correo,
        defaultRFC: res.rfc,
        defaultCURP: res.curp,
        dafaultPhone: res.telefono,
        defaultCellphone: res.celular,
        twoFactorAuth: res.twoFactorAuth
      })
      this.user = res;
      console.log("Cargando usuario");
      if(res.img64== null || res.img64==undefined || res.img64 == ""){
        console.log("No hay imagen");
        this.imagenCargada ="data:image/png;base64,"+res.img64Default;
      }else{
        this.imagenCargada ="data:image/jpeg;base64,"+res.img64;
      }
      console.log(res);
    }, err => {
      if (err.status == 401)
        this.toastMsg.warning('Es necesario iniciar sesión.');
      else
        this.toastMsg.error('Se ha producido un error inesperado.');
    });
  }

  setUserValues(){
    this.user.nombre = this.formUser.value.defaultFormName
    this.user.apellido_m = this.formUser.value.defaultFormFirstName
    this.user.apellido_p = this.formUser.value.defaultFormLastName
    this.user.curp = this.formUser.value.defaultCURP
    this.user.rfc = this.formUser.value.defaultRFC
    this.user.telefono = this.formUser.value.dafaultPhone
    this.user.celular = this.formUser.value.defaultCellphone
    this.user.contrasenia = this.formUser.value.contrasenia
    this.user.contraseniaConfirma = this.formUser.value.contraseniaConfirma
    this.user.twoFactorAuth = this.formUser.value.twoFactorAuth
  }

  saveData() {
    console.log(this.formUser, 'formUser------------')
    if (this.formUser.valid == false) {
      this.toastMsg.warning('Favor de llenar todos los campos');
      ValidatorHelper.validateAllFormFields(this.formUser);
      return;
    }
    if(this.imgBase64!=null){
      this.user.img64= this.imgBase64;
    }
    
    if (!isNullOrUndefined(this.user.rfc))
      this.user.rfc = this.user.rfc.toUpperCase();

    if (!isNullOrUndefined(this.user.curp))
      this.user.curp = this.user.curp.toUpperCase();
    
    this.setUserValues()
    
    console.log("*******************");
    console.log(this.user);
    console.log("*******************");

    if (this.user && !isNullOrUndefined(this.user.contrasenia)) {
      if (this.user.contrasenia !== this.user.contraseniaConfirma) {
        this.toastMsg.warning('Favor de llenar todos los campos1');
      } else {
        
        this.userService.actualizarUsuarioEnSesion(this.user).then(res => {
          this.toastMsg.success('La operación ha sido exitosa.');
          this.modal.hide();
        }, err => {
          if (err.status == 400 && err.body)
            this.toastMsg.error(err.body.message);
          else
            this.toastMsg.error('Se ha producido un error inesperado.');
        });
      }
    } else {
      this.userService.actualizarUsuarioEnSesion(this.user).then(res => {
        this.toastMsg.success('La operación ha sido exitosa.');
        this.modal.hide();
      }, err => {
        if (err.status == 400 && err.body)
          this.toastMsg.error(err.body.message);
        else
          this.toastMsg.error('Se ha producido un error inesperado.');
      });
    }
  }
  abrirFormularios(){
    let url = window.location.origin+"#/portal/portal_empleados";
    location.href = url;
    console.log("abriendo forms");
    this.modal.hide();
  }


  onUploadOutput(output: UploadOutput | any): void {
    console.log(output);
    if(output.type === 'done'){
      
      this.imagenCargada = "data:image/jpeg;base64,"+output.file.response.base64;
      this.imgBase64 = output.file.response.base64;
    }
    if (output.type === 'addedToQueue') {
      if (output.file.size < 3145728) {
        if (output.file.nativeFile.type != 'image/png' && output.file.nativeFile.type != 'image/jpg' &&
          output.file.nativeFile.type != 'image/jpeg') {
          this.toastMsg.warning('Formato incorrecto, intente cargando una imagen de tipo: "jpg", ' +
            '"jpeg", "png"');
        } else {
          this.file = output.file;
          setTimeout(() => {
            // Retraso de subida a servidor
            this.startUpload();
           // this.showFiles();
          });
        }
      } else {
        this.toastMsg.warning('El archivo tiene que ser menor a 3 megabytes');
      }
    }
    if (output.type === 'done') {
      if (output.file.size < 3000) {
        this.file = output.file;
      }
    }
  }


  startUpload(): void {

    const event: UploadInput = {
      type: 'uploadFile',
      url: environment.apiBaseUrl + '/api/configuracion/na/icons',
      method: 'POST',
      file: this.file
    };
    console.log('Petición a enviar');
    console.log(event);
    this.uploadInput.emit(event);
    this.imagenCargada = true;

}
}



