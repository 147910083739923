
import {Injectable} from '@angular/core';
import {IResourceMethod, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';
import {MotivosModel} from './motivos-rechazo.model';
import {ResourceAuth} from '../../common.module/auth/authenticate.resource';
import {AuthServerProvider} from '../../common.module/auth/auth-jwt.service';

@Injectable()
@ResourceParams({
  pathPrefix: '/coremicro'
  // url: 'http://localhost:8022'
})

export class MotivoRechazoResource extends ResourceAuth {
 constructor(restHandler: ResourceHandler, authProv: AuthServerProvider) {
   super(restHandler, authProv);
 }
//  export class MotivoRechazoResource extends Resource {
//    constructor(restHandler: ResourceHandler) {
//      super(restHandler);
//    }

  @ResourceAction({
    path: '/rejection_ordered_by_id'
  })
  getAll: IResourceMethod<any, MotivosModel[]>;

  @ResourceAction({
    path: '/rejection_by_requirement_id/{!id}'
  })
  getAllByDocument: IResourceMethod<{id: number}, MotivosModel[]>; 

  @ResourceAction({
    path: '/rejection_by_tramite_id/{!tramite_id}'
  })
  getAllByRejectionByTramite: IResourceMethod<{tramite_id: string}, MotivosModel[]>; 

  @ResourceAction({
  })
  getOne: IResourceMethod<{ id: any }, MotivosModel>;

  @ResourceAction({
    path: '/rejection',
    method: ResourceRequestMethod.Post
  })
  save: IResourceMethod<MotivosModel, any>;

  @ResourceAction({
    path: '/rejection',
    method: ResourceRequestMethod.Patch
  })
  update: IResourceMethod<MotivosModel, any>;

  @ResourceAction({
    path: '/rejection/{!id}',
    method: ResourceRequestMethod.Patch
  })
  deleteMotivo: IResourceMethod<{ id: number }, any>;
}
