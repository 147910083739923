import {DirectivasModule} from './directivas.module/directivas.module';
import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MDBSpinningPreloader, ToastModule} from 'ng-uikit-pro-standard';
import {MainLoginComponent} from './main-login.component/main-login.component';
import {PageNotFoundComponent} from './components/generic/not-found/not-found.component';
import {AppCommonLibsModule} from './common.module/common.module';
import {AuthResource} from './main-login.component/service/auth.resource';
import {NgProgressModule} from 'ngx-progressbar';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {LoginRepository} from './main-login.component/service/login.repository';
import {LoginService} from './main-login.component/service/login.service';
import {DbBaseRepository} from './common.module/util/db.base.repository';
import {TestComponent} from './TestTemplate/TestTemplate.component';
import {NotificationsModalComponent} from './main-portal.component/notifications-modal.component/notifications-modal.component';
import {MainPortalComponent} from './main-portal.component/main.component';
import {HeaderComponent} from './main-portal.component/header.component';
import {FooterComponent} from './main-portal.component/footer.component';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {DatosUsuarioComponent} from './main-portal.component/datos-usuario.component/datos-usuario.component';
import {FormalitiesResource} from './main-portal.component/service/formalities.resource';
import {NotificationsResource} from './main-portal.component/service/notifications.resource';
import {UsuarioService} from './main-portal.component/login.component/service/usuario.service';
import {UsuarioResource} from './main-portal.component/login.component/service/usuario.resource';
import {MainAdminComponent} from './main-admin.component/main.component';
import {IndexAdminComponent} from './main-admin.component/index-admin.component';
import {SidebarComponent} from './main-admin.component/sidebar.component';
import {ProvidersService} from "./portal-legacy/portal.providers.module/service/providers.service";
import {ProvidersResource} from "./portal-legacy/portal.providers.module/service/providers.resource";
// import {ProvidersResource} from './portal.index.module/service/providers.resource';
import {ChatComponent} from './common.module/components/chat-component/chat.component';
import {ChatAdminComponent} from './common.module/components/chat-component/chat-admin.component';
import {ChatRequestResource} from './common.module/components/chat-component/service/chat-request.resource';
import {RecoverAccountComponent} from './main-recover.component/recover-account.component';
import {RegistryComponentComponent} from './main-registry.component/registry-continue.component';
import {AdminConsultaExpElecModule} from './admin.consulta-exp-elec.module/consulta-exp-elec.module';
import {ConsultaPagoModule} from './portal.consulta-pago.module/consulta-pago.module';
import {AdminTestComponent} from './admin.test.component/admin-test.component';
import {LayoutListComponent} from './components/generic/layout-list/layout-list.component';
import {ModalConfirmComponent} from './components/generic/modal-confirm/modal-confirm.component';
import {AccountComponent} from './main-admin.component/account.component/account.component';
import {IndexComponent} from './ruta.directorio.componente/index.component';
import {DynamicFormComponent} from './TestTemplate/formDynamic.component';
import {EvaluacionResource} from './common.tramite.module/evaluacion-ciudadana/service/evaluacion.resource';
import {EvaluacionService} from './common.tramite.module/evaluacion-ciudadana/service/evaluacion.service';
import {AvisosCiudadanoComponent} from './main-portal.component/avisos-ciudadano/avisos-ciudadano.component';
import {AvisosCiudadanoResource} from './main-portal.component/service/avisos-ciudadano.resource';
import {AgmCoreModule} from '@agm/core';
import {ChatConfiguracionResource} from './common.module/components/chat-component/service/chat-configuracion.resource';
import {DataSessionShareService} from './common.module/util/data-share-session.service';
import {ConfiguracionGeneralService} from './admin.configuracion-general.module/service/configuracion-general.service';
import {ConfiguracionGeneralResource} from './admin.configuracion-general.module/service/configuracion-general.resource';
import {AuthExtendedResource} from './main-login.component/service/auth-extended.resource';
import localeEsMx from '@angular/common/locales/es-MX';
import {registerLocaleData} from '@angular/common';
import {AdminAdminModule} from './admin.admin.module/admin-admin.module';
import { ConsultaResolutivoModule } from './portal.consulta-resolutivo/consulta-resolutivo.module';
import { MainLoginConsumosComponent } from './main-login-app-consumos.component/main-login-app-consumos.component';
import {LoginServiceConsumo} from './main-login-app-consumos.component/service/login.service';
import {LoginRepositoryConsumos} from './main-login-app-consumos.component/service/login.repository';
import {AuthResourceConsumos} from './main-login-app-consumos.component/resource/auth.resource';
import {ConfiguracionGeneralServiceConsumos} from './main-login-app-consumos.component/service/configuracion-general.service';
import {ConfiguracionGeneralResourceConsumos} from './main-login-app-consumos.component/resource/configuracion-general.resource';
import {MainAdminConsumosComponent} from './main-admin-consumos.component/main.component';
import {SidebarComponentConsumos} from './main-admin-consumos.component/sidebar-consumos.component';
import {AuthExtendedResourceConsumos} from './main-login-app-consumos.component/resource/auth-extended.resource';
import {IndexAdminComponentConsumos} from './main-admin-consumos.component/index-admin.component';
import {DirectivasModuleConsumos } from './directivas-consumos.module/directivas-consumos.module';
import {AuthServerProviderConsumos} from './common.module/auth/auth-jwt-consumos.service';
import {TramitesService} from './admin.index.module/service/tramites.service';
import {TramitesResource} from './admin.index.module/service/resource/tramites.resource';
import { CancelarpasescajaModule } from './admin.cancelarpasescaja.module/cancelarpasescaja.module';
import {MatAutocompleteModule, MatCheckboxModule, MatButtonModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, MatRadioModule,
        MatSelectModule, MatSlideToggleModule} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

//import { CalendarioComponent } from './portal.calendario-empleados.module/calendario/calendario.empleado';
//import {AdminAdminConsumosModule} from './admin.admin-consumos.module/admin-admin.module';
//import { CalendarioEmpleadosModule } from './portal.calendario-empleados.module/calendarioEmpleados.module';
// registerLocaleData(localeEs, 'es');
import {RequestRegistrationAndEndorsementService} from './portal-legacy/portal.providers.module/service/request-registration-endorsement-consultation-update.service';
import {RequestRegistrationAndEndorsementResource} from './portal-legacy/portal.providers.module/service/request-registration-endorsement-consultation-update.resource';
import { PaymentService } from './portal-legacy/portal.payment.success.module/service/payment.service';
import { PaymentResource } from './portal-legacy/portal.payment.success.module/service/payment.resource';
import { PaymentSuccessModule } from './portal-legacy/portal.payment.success.module/payment-success.module';
import { LocationService } from './common.module/service/location.service';
import { LocationResource } from './common.module/resource/location.resource';
import { EditorModule } from '@tinymce/tinymce-angular';
import { MotivosRechazoModule } from './admin.motivos-rechazo.module/motivos-rechazo.module';

// import {AdminAdminConsumosModule} from './admin.admin-consumos.module/admin-admin.module';
registerLocaleData(localeEsMx, 'es-MX');
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    AppComponent,
    MainPortalComponent,
    HeaderComponent,
    FooterComponent,
    MainPortalComponent,
    NotificationsModalComponent,
    MainLoginComponent,
    TestComponent,
    DynamicFormComponent,
    PageNotFoundComponent,
    // LoginCitizenComponent,
    DatosUsuarioComponent,
    MainAdminComponent,
    IndexAdminComponent,
    SidebarComponent,
    ChatComponent,
    ChatAdminComponent,
    RecoverAccountComponent,
    RegistryComponentComponent,
    AdminTestComponent,
    LayoutListComponent,
    ModalConfirmComponent,
    AccountComponent,
    RecoverAccountComponent,
    IndexComponent,
    AvisosCiudadanoComponent,
    MainLoginConsumosComponent,
    MainAdminConsumosComponent,
    SidebarComponentConsumos,
    IndexAdminComponentConsumos,
    //PaymentSuccessCreateComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppCommonLibsModule,
    NgProgressModule,
    ToastModule.forRoot({tapToDismiss: true, timeOut: 12000, toastClass: 'opacity-none'}),
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    PerfectScrollbarModule,
    AdminConsultaExpElecModule,
    ConsultaPagoModule,
    ConsultaResolutivoModule,
    PaymentSuccessModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDnD1KfsnmR-s5cysMgYNfVwUcyurCs8lo',
      libraries: ['places']
    }),
    AdminAdminModule,
    DirectivasModule,
    AgmCoreModule.forRoot(),
    DirectivasModuleConsumos,
    CancelarpasescajaModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatRadioModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    PaymentSuccessModule,
    EditorModule,
   // AdminAdminConsumosModule
   // CalendarioEmpleadosModule
   MotivosRechazoModule
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'es-MX'},
    MDBSpinningPreloader,
    BrowserAnimationsModule,
    AuthResource,
    AuthExtendedResource,
    LoginRepository,
    LoginService,
    DbBaseRepository,
    AuthServerProviderConsumos,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    FormalitiesResource,
    NotificationsResource,
    UsuarioService,
    UsuarioResource,
    ProvidersService,
    ProvidersResource,
    ChatRequestResource,
    ChatConfiguracionResource,
    ConfiguracionGeneralResource,
    ConfiguracionGeneralService,
    EvaluacionResource,
    EvaluacionService,
    AvisosCiudadanoResource,
    DataSessionShareService,
    LoginServiceConsumo,
    LoginRepositoryConsumos,
    ConfiguracionGeneralServiceConsumos,
    AuthResourceConsumos,
    ConfiguracionGeneralResourceConsumos,
    AuthExtendedResourceConsumos,
    TramitesService,
    TramitesResource,
    RequestRegistrationAndEndorsementService,
    RequestRegistrationAndEndorsementResource,
    PaymentResource,
    PaymentService,
    LocationService,
    LocationResource
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
