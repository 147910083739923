<header [ngClass]="{'loginPrincipal': ocultarNavegacion}" class="fixed-top" mti-font-titulos>
  <div mti-color-theme class="w-100 header-top" (mouseover)="ocultarMenu()">
    <div class="container display-flex">
      <logo class="img-logo-top-principal" *ngIf="configuracion.seccionInformacion == 'contenido'">
        <span>
          <a class="logo navbar-brand" href="#/portal">
            <img alt="Logo" *ngIf="configuracion.logoCabecera"
              [src]="'data:image/jpg;base64,'+configuracion.logoCabecera">
          </a>
        </span>
      </logo>
      <ul class="navbar-nav top-nabvar ml-auto">
        <li class="estiloUsuarioLogueadoPortal">
          <p>{{this.usuarioPortal}}</p>
        </li>
        <li class="nav-item centrar-vertical" routerLink="/portal" mdbWavesEffect>
          <a mti-color-theme class="nav-link link-landing"><i title="Inicio" class="fa fa-home"></i></a>
        </li>
        <li class="nav-item centrar-vertical ml-3 mr-1" mdbWavesEffect
          *ngIf="!user || user.discriminator =='closeSession'">
          <a mti-color-theme class="nav-link link-landing" (click)="formLogin.modal.show()" #myLink>
            <i title="Iniciar sesión" class="fa fa-user"></i>
          </a>
        </li>
        <li class="nav-item dropdown centrar-vertical ml-3" dropdown #dropdown='bs-dropdown' *ngIf="user">
          <a dropdownToggle mdbWavesEffect mti-color-theme type="button" (click)="setShowBoxNotifications()"
            class="nav-link">
            <i title="Notificaciones" class="fas fa-bell"></i><span class="caret"></span>
          </a>
          <span class="counter-notifications-portal" *ngIf="notificationsCount > 0">{{notificationsCount}}</span>
          <ul *dropdownMenu class="dropdown-menu dropdown dropdown-primary dropdown-noti" role="menu">
            <perfect-scrollbar style="max-height: 255px">
              <ul class="list-group">
                <li class="list-group-item text-muted px-2 py-1 cursor-pointer"
                  *ngFor="let notification of notifications"
                  (click)="dropdown.show(); seenNotification(notification, false);">
                  <small class="float-right"><strong>{{notification?.fecha_creacion?.$date | date: 'dd/MM/yyyy hh:mm a'
                      }}</strong>
                  </small>
                  <div class="clearfix"></div>
                  <div class="d-flex align-items-center">
                    <small>
                      {{notification.detalle + " "}}
                      <a class="position-relative"
                        *ngIf="notification.app_path != null && notification.app_path != undefined"
                        href="{{notification.app_path}}">Ir a trámite</a>
                    </small>
                    <div class="ml-1" *ngIf="notification.fecha_visto==null"
                      style="width: 10px;min-width: 10px;max-width: 10px;height: 10px;min-height: 10px;max-height: 10px;background-color: rgb(0, 81, 231);border-radius: 50%;">
                    </div>
                  </div>
                </li>
                <li class="list-group-item text-muted text-center" *ngIf="notifications?.length == 0">
                  <small>Sin notificaciones por el momento</small>
                </li>
              </ul>
            </perfect-scrollbar>
            <li>
              <a href="javascript: void(0)" mti-color-hover-item class="dropdown-item waves-light text-center"
                mdbWavesEffect (click)="notificationsModal.modal.show()">
                Ver todas las notificaciones
              </a>
            </li>

          </ul>
        </li>
        <div class="z-depth-1" *ngIf="verMenuMovil && !routeLanding"
          style="background-color: #fff;position: absolute;top:42px;z-index:9;right: 50px;padding:0.25rem 0.5rem;">
          <input type="text" class="form-control hd-item-busqueda-movil my-icon content-busqueda"
            placeholder="Búsqueda..." (keyup)="loadFormalities()" (focus)="showList()" (click)="loadCategorias()"
            (blur)="hideList()" [(ngModel)]="searchStr.s">
        </div>
        <li class="nav-item centrar-vertical ml-3" *ngIf="!routeLanding" mdbWavesEffect mdbDropdown>
          <a mti-color-theme *ngIf="!verBusqueda" (click)="mostrarMenuMovil()" class="nav-link waves-light"
            title="Busqueda">
            <i class="fas fa-search"></i>
          </a>
          <input type="text" *ngIf="verBusqueda" class="form-control hd-item-busqueda my-icon content-busqueda"
            placeholder="Búsqueda..." (keyup)="loadFormalities()" (focus)="showList()" (click)="loadCategorias()"
            (blur)="hideList()" [(ngModel)]="searchStr.s">
        </li>
        <!-- Resultados de la búsqueda -->
        <div *ngIf="showBusqueda"
          class="list-group position-absolute dropdown col-12 col-lg-12 shadow p-0 panel-busqueda">
          <div *ngFor="let form of resultadoBusqueda" style="margin-bottom: -4px;">
            <a href="javascript: void(0)"
              class="list-group-item d-flex align-items-center font-weight-bold waves-light text-muted flipInX fast-4"
              *ngIf="form.idCategoria" (click)="detalleCategoria(form.idCategoria)" mdbWavesEffect>
              {{ form.nombre }}
            </a>
            <a href="javascript: void(0)"
              class="list-group-item d-flex align-items-center font-weight-bold waves-light text-muted flipInX fast-4"
              *ngIf="form._id" (click)="detalleCategoria(form._id)" mdbWavesEffect>
              {{ form.nombre }}
            </a>
            <a href="javascript: void(0)"
              class="list-group-item d-flex align-items-center waves-light text-muted flipInX fast-4"
              *ngIf="form.idTramite && !form.idCategorias" (click)="detalleTramite(form)" mdbWavesEffect>
              <mdb-icon fas icon="angle-right"></mdb-icon>
              <span>&nbsp;{{ form.nombre }}</span>
            </a>
          </div>
          <a href="javascript: void(0)" class="list-group-item waves-light text-center text-muted flipInX fast-4"
            *ngIf="resultadoBusqueda.length < 1" mdbWavesEffect>
            {{ mensajeNoBusqueda }}
          </a>
        </div>
        <li class="nav-item centrar-vertical ml-2 dropdown" *ngIf="user" dropdown>
          <a mti-color-theme dropdownToggle type="button" class="nav-link dropdown-toggle btn-header-user">
            <i class="fa fa-user"></i><span class="caret"></span></a>
          <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary dropdown-user" role="menu">
            <a class="dropdown-item top-nav" href="javascript: void(0)"
              routerLink="/portal/ver-expediente-electronico">Expediente electrónico</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item top-nav" href="javascript: void(0)" routerLink="/portal//bandeja_entrada">Mis
              trámites</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item top-nav" href="javascript: void(0)" routerLink="/portal/formalities">Mis
              trámites Mc</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item top-nav" href="javascript: void(0)" (click)="formDataUser.modal.show()">Mis
              datos</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item top-nav" href="javascript: void(0)" (click)="logout()">Cerrar sesión</a>
          </div>
        </li>
        <li *ngIf="this.consultarComprobantes" class="nav-item centrar-vertical ml-2 dropdown" dropdown>
          <a mti-color-theme dropdownToggle type="button"
            class="nav-link dropdown-toggle btn-header-user oculta-icono display-flex">
            <span class="caret"></span><mdb-icon fas icon="angle-down" size="lg" class="m-auto"></mdb-icon> </a>
          <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary dropdown-user" role="menu">
            <a class="dropdown-item top-nav" href="javascript: void(0)" routerLink="/portal/consulta-pago">Consultar
              comprobantes</a>
            <a class="dropdown-item top-nav" href="javascript: void(0)" routerLink="/portal/traslado/calculator/transfer-domain">
              Calculadora de TD</a>
            <div class="dropdown-divider"></div>
            <span class="dropdown-header">Gestión de CFDI's</span>
              <ul>
                <li>
                  <a class="dropdown-item waves-light" mdbWavesEffect
                     href="javascript: void(0)" routerLink="/portal/nomina-cfdi">Recibos de nómina</a>
                </li>
                <li>
                  <a class="dropdown-item waves-light" mdbWavesEffect
                     href="javascript: void(0)" routerLink="/portal/pagos">Comprobantes fiscales de pago /<br>Recibos</a>
                </li>
                <li>
                  <a class="dropdown-item waves-light" mdbWavesEffect
                     href="javascript: void(0)" routerLink="/portal/receptioncfdi">Recepción de CFDI's</a>
                </li>
                <li>
                  <a class="dropdown-item waves-light" mdbWavesEffect
                     href="javascript: void(0)" routerLink="/portal/actualizardatosfiscales">Actualizar Datos Fiscales</a>
                </li>
              </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <mdb-navbar #navbarLP SideClass="navbar navbar-expand-lg navbar-light navbar-landing" [containerInside]="true">
    <!-- *ngIf="configuracion.seccionInformacionSMA == 'encabezado'" -->
    <!--logo (mousemove)="ocultarMenu()" class="img-logo-principal p-2 bg-white d-flex link">
      <span class="d-flex justify-content-center align-items-center">
        <a class="logo navbar-brand mr-0" href="#/portal">
          <img alt="logo" *ngIf="configuracion.logoCabeceraSMA"
               [src]="'data:image/jpg;base64,'+configuracion.logoCabeceraSMA">
        </a>
      </span>
    </logo-->
    <logo (mouseover)="ocultarMenu()" class="med-logo logo-two-bg">
      <span>
        <a class="navbar-brand" href="#/portal">
          <img alt="logo" class="img-responsive" *ngIf="configuracion.logoCabecera"
            [src]="'data:image/jpg;base64,'+configuracion.logoCabecera">
        </a>
      </span>
    </logo>
    <logo class="med-logo logo-not-movil"></logo>
    <links>
      <ul class="navbar-nav ul-nav-menu" style="max-height: 150px;overflow-y: auto;">
        <li class="nav-item dropdown mega-dropdown" *ngIf="menu.length < 1">&nbsp;</li>
        <li class="nav-item dropdown mega-dropdown" style="margin-top:-3px;">
          <a type="button" class="nav-link link-cat btn-header-user oculta-icono display-flex"
              style="width: max-content;"
              routerLink="/portal/contratistas">
            CONTRATISTAS
          </a>
        </li>
        <li class="nav-item dropdown mega-dropdown" style="margin-top:-3px;">
          <a type="button" class="nav-link link-cat btn-header-user oculta-icono display-flex"
              style="width: max-content;"
              routerLink="/portal/proveedores">
            PROVEEDORES
          </a>
        </li>
        <li class="nav-item dropdown mega-dropdown" style="margin-top:-3px;">
          <a type="button" class="nav-link link-cat btn-header-user oculta-icono display-flex"
              style="width: max-content;"
              routerLink="/portal/traslado">
            TRASLADO DE DOMINIO
          </a>
        </li>
        
        <li class="nav-item dropdown mega-dropdown" *ngFor="let seccion of menu;let indexSeccion = index;" mdbDropdown
          data-toggle="collapse" style="margin-top:-3px;" data-target=".navbar-collapse.show">
          <a mdbDropdownToggle class="nav-link link-cat" style="width: max-content;" aria-haspopup="true"
            (click)="remStyle($event);" (mouseover)="verMenu($event, indexSeccion)"
            [ngStyle]="seccion.active?{fontColor: '#292929'}:''" aria-expanded="false">
            {{ seccion.nombre }}
          </a>
          <div class="dropdown-menu mega-menu menu-mega-head z-depth-1 menu-mega-sm p-0" (mouseleave)="ocultarMenu();"
            (click)="checkDropdownMM($event)">
            <div class="container-fluid bg-mega-menu" mti-color-theme>
              <div class="row">
                <div
                  class="col-12 col-lg-3 col-md-3 pt-3 pb-3 pl-4 pr-0 color-mega-menu overflow-y-auto overflow-x-hidden sidebar-mega-menu"
                  mti-color-theme>
                  <ng-container *ngFor="let tipoCategoria of seccion.tiposCategoria; let indexTC = index">
                    <ng-container *ngIf="tipoCategoria.categorias.length>0">
                      <div class="w-100 h6">
                        {{ tipoCategoria.nombre }}
                      </div>
                      <ul class="list-unstyled lista-categorias-mega-menu mb-3">
                        <ng-container *ngFor="let categoria of tipoCategoria.categorias; let indexCategorias = index;">
                          <li *ngIf="categoria.estatus"
                            (mouseover)="verTramites(indexSeccion, categoria.nombre, categoria._id, false)"
                            class="link item-categoria-mega-menu display-flex pt-1 pb-1" style="font-size: 14px;"
                            [ngStyle]="categoria.active==true ? {borderRight: 'solid 5px ' + configuracion.groupElements2,backgroundColor: 'rgba(255, 255, 255, 0.3)'} : {borderRight: 'solid 5px transparent'}">
                            <div style="margin: 0 5px;">
                              <img [src]="'data:image/jpg;base64,'+categoria.archivo" alt="Categoria" width="15px"
                                height="15px" *ngIf="categoria.archivo !='' && categoria.archivo">
                              <i class="fa fa-folder mr-1" *ngIf="categoria.archivo =='' || !categoria.archivo"></i>
                            </div>
                            <div style="margin:0;">{{ categoria.nombre }}</div>
                          </li>
                        </ng-container>
                      </ul>
                    </ng-container>
                  </ng-container>
                </div>
                <div class="col-12 col-lg-9 col-md-9 pt-2 pb-2 bg-white content-mega-menu">
                  <div class="w-100 h5 principal-texto display-flex">
                    Trámites
                  </div>
                  <hr>
                  <section class="container-fluid mr-0 p-0">
                    <div class="row">
                      <div class="col-12" style="height: auto;max-height: 300px;">
                        <div style="color:black !important;">
                          {{ tramitesMM[indexSeccion] }}
                        </div>
                        <mdb-carousel #carouselMegaMenu *ngIf="tramitesMM[indexSeccion]?.sliders?.length>0"
                          [isControls]="true" mti-color-theme
                          class="carousel-multi-item multi-animation testimonial-carousel carousel-content-mega-menu"
                          [type]="'carousel-multi-item'" [animation]="'slide'" [interval]="0">
                          <mdb-slide style="height: auto; line-height: 15px;"
                            *ngFor="let slider of tramitesMM[indexSeccion]?.sliders">
                            <div class="row">
                              <ng-container *ngIf="slider?.tramites?.length>0">
                                <ng-container *ngIf="tipoCategoriaMM[indexSeccion]=='r'">
                                  <div class="col-12 col-sm-6 col-md-6 col-lg-4 principal-texto py-1"
                                    *ngFor="let tramite of slider?.tramites">
                                    <mdb-card [class]="'tarjetas-dc-animacion mr-1'"
                                      [ngStyle]="{borderLeft: 'solid 5px ' + configuracion.groupElements2}">
                                      <mdb-card-body class="body-card-denuncia">
                                        <mdb-card-title>
                                          <h6
                                            class="d-flex font-weight-bold justify-content-center align-items-center text-center titulo-card-denuncia">
                                            {{ tramite.nombre }}</h6>
                                          <hr>
                                        </mdb-card-title>

                                        <mdb-card-text>

                                          <p class="descripcion-card-denuncia">{{ tramite.descripcion }}</p>

                                          <div *ngIf="tramite.enLinea">
                                            <button type="button" (click)="cargarTramite(tramite);" mti-color-theme
                                              class="btn btn-sm btn-theme-primary m-auto display-block">
                                              Reportar
                                            </button>
                                          </div>

                                          <div class="ml-auto" *ngIf="tram?.enLinea && !userDecode">
                                            <span class="badge badge-warning"
                                              style="white-space:normal;display:inline-flex">Es necesario iniciar sesión
                                              para realizar Reportar en línea.</span>
                                          </div>
                                          <div class="ml-auto" *ngIf="tram?.enLinea && !tram?.flujo && userDecode">
                                            <span class="badge badge-warning"
                                              style="white-space:normal;display:inline-flex">Trámite no disponible.
                                              Comuníquese con el administrador del sistema</span>
                                          </div>
                                        </mdb-card-text>
                                      </mdb-card-body>
                                    </mdb-card>
                                  </div>
                                </ng-container>
                                <ng-container *ngIf="tipoCategoriaMM[indexSeccion]=='t'">
                                  <div class="col-12 col-sm-6 col-md-6 col-lg-6 principal-texto"
                                    style="word-wrap: break-word;" [ngClass]="(indexTramite>1)?'mt-2':''"
                                    *ngFor="let tramite of slider?.tramites; let indexTramite = index;">
                                    <mdb-card class="tarjetas-dc-animacion link"
                                      [ngStyle]="{borderLeft: 'solid 5px ' + configuracion.groupElements2}"
                                      style="margin: 2px;" (click)="detalleTramite(tramite); hideNav(navbarLP);">
                                      <mdb-card-body style="padding: 15px !important; height: 130px;">
                                        <mdb-card-text class="text-center">
                                          <div class="d-flex" style="height: 100px;overflow-y: auto;">
                                            <h6 class="m-auto">{{ tramite.nombre }}</h6>
                                          </div>
                                        </mdb-card-text>
                                      </mdb-card-body>
                                    </mdb-card>
                                  </div>
                                </ng-container>
                              </ng-container>
                            </div>
                            <ng-container *ngIf="slider?.tramites?.length<1">
                              {{ sinTramites }}
                            </ng-container>
                          </mdb-slide>
                        </mdb-carousel>
                        <p class="principal-texto" *ngIf="tramitesMM[indexSeccion]?.sliders?.length<1">
                          {{ sinTramites }}
                        </p>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li *ngIf="configuracion.menu_empleados && user" class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show" style="margin-top:-3px;">
          <a mdbDropdownToggle class="nav-link link-cat" style="width: max-content;" aria-haspopup="true"
            [ngStyle]="{fontColor: '#292929'}" aria-expanded="false"
            routerLink="portalEmpleados">
            {{configuracion.nombre_menu_empleados}}
          </a>
        </li>
        <div *ngIf="mostrarPagoPredial">
          <li class="nav-item dropdown mega-dropdown" style="margin-top:-3px;">
            <a class="nav-link link-cat" style="width: max-content;" aria-haspopup="true" aria-expanded="false"
              routerLink="/portal/consulta-adeudos">
              {{configuracion.nombrePredial}}
            </a>
          </li>
        </div>

        <div *ngIf="mostrarPagoAgua">
          <li class="nav-item dropdown mega-dropdown" style="margin-top:-3px;">
            <a class="nav-link link-cat" style="width: max-content;" aria-haspopup="true" aria-expanded="false"
              routerLink="/portal/consulta-adeudos-agua">
              {{configuracion.nombreAgua}}
            </a>
          </li>
        </div>

        <li class="nav-item dropdown mega-dropdown" style="margin-top:-3px;">
          <a type="button" class="nav-link link-cat btn-header-user oculta-icono display-flex"
              style="width: max-content;"
              routerLink="/portal/donacion">
            DONATIVOS
          </a>
        </li>
        <li class="nav-item dropdown mega-dropdown" style="margin-top:-3px;" dropdown>
          <a dropdownToggle type="button" class="nav-link link-cat dropdown-toggle btn-header-user oculta-icono display-flex"
              style="width: max-content;">
            PREDIAL
          </a>
          <div *dropdownMenu style="left: auto !important;" class="dropdown-menu dropdown dropdown-primary" role="menu">
            <a class="dropdown-item top-nav" routerLink="/portal/predial">Pago</a>
            <a class="dropdown-item top-nav" routerLink="/portal/aviso">Aviso recibo</a>
          </div>
        </li>
        <li class="nav-item dropdown mega-dropdown" style="margin-top:-3px;" dropdown>
          <a dropdownToggle type="button" class="nav-link link-cat dropdown-toggle btn-header-user oculta-icono display-flex"
              style="width: max-content;">
            ALBERCAS
          </a>
          <div *dropdownMenu style="left: auto !important;" class="dropdown-menu dropdown dropdown-primary" role="menu">
            <a class="dropdown-item top-nav" routerLink="/portal/pool/request">Solicitud</a>
            <a class="dropdown-item top-nav" routerLink="/portal/pool/request/pay/pooluse">Pago</a>
          </div>
        </li>
        <li class="nav-item dropdown mega-dropdown" style="margin-top:-3px;">
          <a type="button" class="nav-link link-cat btn-header-user oculta-icono display-flex"
              style="width: max-content;"
              routerLink="/portal/cash/pass">
            PASE DE CAJA
          </a>
        </li>
        <li *ngIf="canShowInmobiliaria" class="nav-item dropdown mega-dropdown" style="margin-top:-3px;">
          <a type="button" class="nav-link link-cat btn-header-user oculta-icono display-flex"
              style="width: max-content;"
              routerLink="/portal/statement">
            INMOBILIARIAS
          </a>
        </li>
        <li class="nav-item dropdown mega-dropdown" style="margin-top:-3px;">
          <a type="button" class="nav-link link-cat btn-header-user oculta-icono display-flex"
              style="width: max-content;"
              href="https://www.predialseguro.com/" target="_blank">
              PÓLIZA DE SEGURO
          </a>
        </li>
      </ul>
    </links>
  </mdb-navbar>
</header>

<app-login-citizen #formLogin (user)="setUserSession($event)"></app-login-citizen>
<app-data-user #formDataUser></app-data-user>
<app-notifications-modal #notificationsModal
  [borrarNotificacionDeListaYDisminuirContador]="borrarNotificacionDeListaYDisminuirContadorFunc">
</app-notifications-modal>

<div mdbModal #infoModal="mdb-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-notify modal-info modal-lg" role="document">
    <div class="modal-content">
      <div mti-color-theme class="modal-header">
        <button type="button" class="close pull-right text-white" aria-label="Close" (click)="infoModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100 text-center text-white" id="myModalLabel">AVISO IMPORTANTE</h4>
      </div>
      <div class="modal-body text-justify">
        <p>Por medio del presente, se hace del conocimiento que el día 1° (primero) de
          noviembre de 2018, se comunica que únicamente podrán ser capturados e
          ingresados los trámites referentes al pago de impuestos inmobiliarios, de manera
          electrónica a través del Portal en Línea del Municipio de Corregidora, cuya liga es la
          siguiente: https://www.corregidoraenlinea.gob.mx/</p>
        <p>Por lo que refiere al día 02 (dos) de noviembre de 2018, se informa que será
          considerado como día inhábil.</p>
        <p>Lo anterior, para tomar las medidas correspondientes y evitar así, cualquier molestia
          que pueda ocasionarse derivado de los plazos y trámites ingresados ante esta
          Autoridad Fiscal.</p>
        <p class="text-center">Dirección de Ingresos <br>
          Secretaría de Tesorería y Finanzas</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-success btn-rounded waves-effect m-auto" mdbWavesEffect
          (click)="infoModal.hide()">Aceptar
        </button>
      </div>
    </div>
  </div>
</div>