
<!--pruebas: [hidden]="fromExpediente ? main_action != 1 : showReq" -->
<div [hidden]="!enabled">
  <div class="row"  [ngClass]="{disabled: fromExpediente ? false : !enabled}">
    <div class="col-12" >
      <div class="file-field md-form mb-1">
        <div class="btn btn-primary btn-sm waves-light"
            mdbWavesEffect
            (click)="selectValidation($event)">
          <span>Sube tu requisito</span>
          <input accept="application/pdf, application/zip" type="file" mdbFileSelect
                [(ngModel)]="valorInput"
                (uploadOutput)="onUploadRequirement($event)"
                [uploadInput]="uploadInput"
                [disabled]="banIndexRequirement === undefined || (blockLoading && manageLoading.loadCount != 0)">
        </div>
        <div class="file-path-wrapper">
          <input class="file-path" type="text" placeholder="Subir un archivo al requisito..." [value]="showFiles()"
                disabled>
        </div>
      </div>
    </div>
    <div class="col-12  text-center mb-1" *ngIf="main_action != 0">
        <span class="text-muted small">
          * Seleccione el documento que desea subir, en seguida presione el
          botón 'Sube tu requisito'.
        </span>
    </div>
  </div>
</div>

<div class="row" [ngClass]="{'mt-4': estatus_proceso}">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-6" style="height:70vh; overflow-y: scroll;">
        <ul class="list-group list-group-flush mt-2 table-responsive"
            [ngClass]="{'disabled': banVisorOp}">
          <li class="list-group-item justify-content-between align-items-center animated  fast-5 px-3 py-2"
              *ngFor="let req of requirements; let i = index"
              
              [ngClass]="{'NoMarginONLi':(req.requirement.datoAdicional_padre_id &&
                                          escucharPadre(req.requirement.datoAdicional_padre_id,
                                                        req.requirement.datoAdicional_padre_condicion)),
                          'd-flex': !showBtnApproval}">
            <div class="form-check mr-auto pl-0 mr-2 d-flex"
                 [hidden]="req.requirement.datoAdicional_padre_id &&
                           escucharPadre(req.requirement.datoAdicional_padre_id, req.requirement.datoAdicional_padre_condicion)">
              <!-- <input class="form-check-input"
                mdbInput
                [value]="i"
                [defaultChecked]="false"
                [checked]="(banIndexRequirement == i) ? true : false"
                [(ngModel)]="banIndexRequirement"
                [ngModelOptions]="{standalone: true}"
                (change)="changeInput(i)"
                name="req"
                type="radio"
                id="radio{{i}}"> -->
              <div>
                <input class="form-check-input"
                      mdbInput
                      [value]="i"
                      [defaultChecked]="false"
                      [checked]="(banIndexRequirement == i) ? true : false"
                      [(ngModel)]="banIndexRequirement"
                      [ngModelOptions]="{standalone: true}"
                      [disabled]="fromExpediente ? ((main_action == 1 && disCiudadanoAprv ? true : false)  && (blockLoading && manageLoading.loadCount == 0 && getVigencia(req))) : (blockLoading && manageLoading.loadCount != 0 && getVigencia(req))"
                      (click)="changeInput(i)"
                      name="req"
                      type="radio"
                      id="radio{{i}}">
              <label class="form-check-label col-12 requirement-text" for="radio{{i}}">
                {{ req.requirement?.requirement_name }}
                <span class="text-muted small animated fadeIn fast-5"
                      *ngIf="req.uploadFile">
                  [{{req.uploadFile?.name}}]
                </span>
                <span class="fuente-muy-grande" style="color: red" *ngIf="req?.requirement.required">*</span>
              </label>
                <label class="form-check-label col-12 requirement-text" for="radio{{i}}">
                  {{ req.requirement?.descripcion}}
                </label>
                <label class="form-check-label col-12 requirement-text" for="radio{{i}}"
                *ngIf="req.requirement?.progreso">
                  Porcentaje de carga: {{(req.requirement?.cargaCompleta)? req.requirement?.progreso:req.requirement?.progreso-1}}%
                </label>
              </div>

              <div>
                <i class="fa fa-check green-text ml-1"
                  *ngIf="((req.uploadFile?.response || req?.caseFileUploaded?.case_file) && getVigencia(req)) && !req.caseFileUploaded?.nombre_archivo_sistema "></i>
                <i class="fa fa-times red-text ml-1" *ngIf="(!req.uploadFile?.response && !req?.caseFileUploaded?.case_file) && !req.caseFileUploaded?.nombre_archivo_sistema" 
                  [hidden]="req.requirement.datoAdicional_padre_id && escucharPadre(req.requirement.datoAdicional_padre_id,req.requirement.datoAdicional_padre_condicion)"></i>
              </div>
            </div>
       <!-- <mdb-spinner *ngIf="blockLoading && manageLoading.loadCount != 0 && banIndexRequirement == i"
                     spinnerType="small" spinnerColor="blue"></mdb-spinner>
              -->

            <div class="mr-2">
              <div class="custom-control custom-radio mr-1" *ngIf="req?.caseFileUploaded?.case_file && !req?.uploadFile && fromExpediente">
                <input type="radio" class="custom-control-input"
                       id="A{{i}}"
                       name="AN{{i}}" 
                       [disabled]="disCiudadanoAprv && req.caseFileUploaded.estatus == 'A' || main_action == 0 || disCiudadanoAprv && main_action == 1 || disCiudadanoAprv && req.caseFileUploaded.estatus == 'R'"
                       (click)="openModalApprove(i,'A')"
                       [checked]="req.caseFileUploaded.estatus == 'A'"
                       mdbInput>
                <label class="custom-control-label" for="A{{i}}">Aprobado</label>
              </div>
              <div class="custom-control custom-radio"  *ngIf="req?.caseFileUploaded?.case_file && !req?.uploadFile && fromExpediente">
                <input type="radio" class="custom-control-input"
                       id="R{{i}}"
                       name="AN{{i}}"
                       [disabled]="disCiudadanoAprv && req.caseFileUploaded.estatus == 'A' || main_action == 0 || disCiudadanoAprv && main_action == 1"
                       (click)="openModalApprove(i,'R')"
                       [checked]="req.caseFileUploaded.estatus == 'R'"
                       mdbInput>
                <label class="custom-control-label" for="R{{i}}">Rechazado</label>
              </div>
            </div>
            <div *ngIf="req?.caseFileUploaded?.case_file && !req?.uploadFile && fromExpediente ">
              <button type="button" *ngIf="disCiudadanoAprv && req.caseFileUploaded.estatus == 'R' && main_action == 1  "
                      (click)="openModalApprove(i,'V')"
                      mdbTooltip="Click ver detalle"
                      class="btn btn-sm btn-info">Detalle</button>
            </div>

            <!--
            <button type="button" [disabled]="main_action == 0" *ngIf="req?.caseFileUploaded?.case_file && !req?.uploadFile && req.caseFileUploaded.estatus != 'A' && fromExpediente"
                    (click)="openModalApprove(i)"
                    mdbTooltip="{{ disCiudadanoAprv ? '': 'Click para aprobar' }}"
                    placement="right"
                    class="btn btn-sm btn-errror">{{ req.caseFileUploaded.observacion && req.caseFileUploaded.observacion.length > 0 ? 'Rechazado': 'Pendiente' }}</button>

            <button type="button" [disabled]="main_action == 0" *ngIf="req?.caseFileUploaded?.case_file && !req?.uploadFile && req.caseFileUploaded.estatus == 'A' && fromExpediente"
                    (click)="openModalApprove(i)"
                    placement="right"
                    mdbTooltip="{{ disCiudadanoAprv ? '': 'Click para desaprobar' }}"
                    class="btn btn-sm btn-success">Aprobado</button>
            -->

            <button type="button" *ngIf="(req?.caseFileUploaded?.case_file && !req?.uploadFile) && !(req.requirement.datoAdicional_padre_id && escucharPadre(req.requirement.datoAdicional_padre_id,req.requirement.datoAdicional_padre_condicion))"
                    (click)="main_action == 0 ? '':openModalValidity(i)"
                    mdbTooltip="{{req?.caseFileUploaded.vigencia ? req?.caseFileUploaded.vigencia : 'Sin vigencia' }}"

                    class="btn btn-sm btn-info"><mdb-icon far icon="calendar-check"></mdb-icon></button>
            <button type="button" *ngIf="fromExpediente ? ((main_action == 1 && disCiudadanoAprv ? true : false)  && (blockLoading && manageLoading.loadCount == 0 && getVigencia(req))) : (blockLoading && manageLoading.loadCount != 0 && getVigencia(req))" (click)="changeInput(i)" class="btn btn-sm btn-info"><mdb-icon far icon="eye"></mdb-icon></button>
           <!-- <button type="button" *ngIf="req?.requirement.plantilla_doc_id != null" mdbTooltip="Descarga plantilla"
                    placement="left"
                    size="sm"
                    mdbBtn
                    type="button"
                    color="purple"
                    (click)="descargaPlantilla(req.requirement.plantilla_doc_id)">
              <i class="fas fa-file-download left"></i>Plantilla</button>-->
            <!-- Se agrega boton para descargar la plantilla configurada en el requisito -->
              <div>
              <a mdbBtn *ngIf="req?.requirement.plantilla_doc_id != null && req?.requirement.plantilla_doc_id != '' && !escucharPadre(req?.requirement.datoAdicional_padre_id,req.requirement.datoAdicional_padre_condicion)"
                 floating="true"
                 mdbTooltip="Descarga plantilla"
                 placement="left"
                 size="sm"
                 type="button"
                 color="info"
                 mdbWavesEffect
                 (click)="descargaPlantilla(req.requirement.plantilla_doc_id)">
                <mdb-icon fas icon="file-download"></mdb-icon>
              </a>
              <a mdbBtn *ngIf="req?.requirement.plantilla_doc_id != null && req?.requirement.plantilla_doc_id != '' && req?.requirement.datoAdicional_padre_id == null"
              floating="true"
              mdbTooltip="Descarga plantilla"
              placement="left"
              size="sm"
              type="button"
              color="info"
              mdbWavesEffect
              (click)="descargaPlantilla(req.requirement.plantilla_doc_id)">
             <mdb-icon fas icon="file-download"></mdb-icon>
           </a>
            </div>

            <div class="row">
              <div class="col-4 text-center" *ngIf="showBtnApproval">
                <ng-container *ngIf="esPortal">
                  <div class="requirement-text text-center">{{ req.requirement.aprobado ? 'Aprobado': (!req.requirement.motivo_rechazo_id) ? '&nbsp;' : 'Rechazado' }}</div>
                  <a
                    style="margin-top: 3px"
                    [ngClass]="req.requirement.aprobado ? 'btn-floating btn-sm green' : 'btn-floating btn-sm red'"
                    >
                    <i
                      [ngClass]="req.requirement.aprobado ? 'fa fa-check green small' : 'fa fa-close red small'"
                      aria-hidden="true"></i>
                  </a>
                </ng-container>

                <ng-container *ngIf="!esPortal">
                  <div class="requirement-text text-center">{{ req.requirement.aprobado ? 'Aprobado' : 'Rechazado' }} </div>
                  <div class="switch switch primary-switch text-muted font-small mt-1">
                    <label>
                      <input type="checkbox" [(ngModel)]="req.requirement.aprobado"
                            [ngModelOptions]="{standalone: true}"
                            [disabled]="disableApproval"
                            (click)="approveReq($event.target, i, modalReject)">
                      <span class="lever"></span>
                    </label> 
                  </div>
                </ng-container>
              </div>
              <div class="col-4" *ngIf="showBtnObservations">
                <div for="observacionesReq" class="requirement-text">Observaciones</div>
                <button class="btn blue waves-light p-2" id="observacionesReq"
                        (click)="seeObservations(i, modalReject);"
                        [disabled]="req.requirement.aprobado || (!req.requirement.motivo_rechazo_id && esPortal)"
                        data-toggle="modal" data-target="#basicExample" mdbWavesEffect>
                  <i class="fa fa-comment white-text" aria-hidden="true"></i>
                </button>
              </div>
              <div class="col-4" *ngIf="showBtnDownload">
                <div for="descargarReq" class="requirement-text">Descargar</div>
                <a class="btn btn-info w-30 p-2" type="file" id="descargarReq"
                    (click)="downloadFileLoad(i)"
                    [ngClass]="{'disabled': !req?.caseFileUploaded && !req?.uploadFile}">
                  <i class="fa fa-download white-text" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </li>
          <li
            class="list-group-item d-flex justify-content-between align-items-center animated  fast-5 text-center text-muted"
            *ngIf="requirements?.length === 0">
            No se han encontrado requisitos disponibles.
          </li>
        </ul>
      </div>

      <!--  <div class="col-9">
          <img class="card-img-top" src="https://mdbootstrap.com/img/Photos/Others/photo6.jpg" alt="Card image cap">
        </div>
     -->
      <div class="col-lg-6"
           style="height: 30rem;border: 2px gray dashed;">
        <!-- {{requirements | json}}-->
        <app-provider-pdf-format #visor [outputImage]="outputImage">
        </app-provider-pdf-format>

      </div>
      <div class="col-lg-12" style="float: right;text-align: right;">
        <button type="button"
                class="btn btn-sm btn-danger"
                *ngIf="requirements[idDocto]?.uploadFile && !requirements[idDocto]?.caseFileUploaded"
                (click)="deleteRequirementUploaded(idDocto)"><i class="fa fa-trash"></i> ELIMINAR
        </button>
        <ng-container
          *ngIf="!requirements[idDocto]?.uploadFile && requirements[idDocto]?.caseFileUploaded"
        >
          <button type="button"
                  class="btn btn-sm btn-danger"
                  *ngIf="requirements[idDocto]?.caseFileUploaded.case_file != '' &&
                   requirements[idDocto]?.caseFileUploaded.estatus == null &&
                   disCiudadanoAprv == true && main_action == 1"
                  (click)="deleteRequirementUploaded1(idDocto)"><i class="fa fa-trash"></i> ELIMINAR
          </button>

        </ng-container>
        <button type="button"
                class="btn btn-sm btn-primary"
                *ngIf="isDownloaded && requirements[idDocto]?.caseFileUploaded?.case_file"
                (click)="downloadRequirementUploaded(requirements[idDocto])"><i class="fa fa-download"></i> DESCARGAR
        </button>
        <button type="button"
                class="btn btn-sm btn-primary"
                *ngIf="isDownloaded && requirements[idDocto]?.caseFileUploaded?.nombre_archivo_sistema"
                (click)="descargarZip(requirements[idDocto])"><i class="fa fa-download"></i> DESCARGAR
        </button>
      </div>
    </div>
  </div>
</div>


<!--Modal de información esperando descarga . . . -->
<div mdbModal #DescargandoModal="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true" [config]="{backdrop: true, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-notify modal-info" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">Preparando descarga</p>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="text-center">
          <mdb-spinner spinnerColor="blue"></mdb-spinner>
          <!--<mdb-icon fas icon="check" size="4x" class="mb-3 animated rotateIn"></mdb-icon>-->
          <p>Espera mientras preparamos la descarga (puede tomar unos minutos).</p>
        </div>
      </div>

      <!--Footer-->
      <!--<div class="modal-footer justify-content-center">        
        <a type="button" mdbBtn color="primary" outline="true" class="waves-effect" mdbWavesEffect 
        [ngClass]="{disabled: !empiezaDescarga}"(click)="DescargandoModal.hide()">
          Aceptar</a>
      </div>-->
    </div>
    <!--/.Content-->
  </div>
</div>

<div mdbModal #modalReject="mdb-modal" class="modal fade overflow-auto" tabindex="-1" role="dialog"
  aria-labelledby="myBasicModalLabel" aria-hidden="true" [config]="{backdrop: true, ignoreBackdropClick: true}">
  <div class="modal-dialog " role="document">
    <div class="modal-content">
      <div mti-color-theme class="modal-header">
        <button (click)="modalReject.hide();" aria-label="Close" class="close pull-right" type="button">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100">Motivo de rechazo</h4>
      </div>
      <!--div [ngClass]="{disabled: disEdit}" class="modal-body"-->
      <div class="modal-body">
        <div class="row">
          <div class="col-12 col-md-12">
            <form [formGroup]="reasonForRejectionFrm">
              <div class="md-form">
                <div>
                  <ng-container *ngIf="!disableApproval">
                    <div class="col-12">
                      <mdb-select
                        id="reason"
                        [filterEnabled]="true"
                        formControlName="reason"
                        mdbInput
                        [ngClass]="{disabled: disableApproval}"
                        [options]="listReasonRejectionsFiltered"
                        placeholder="Elige el motivo de rechazo">
                      </mdb-select>
                      <label class="col-lg-12" for="reason">Motivo de Rechazo</label>
                      <div *ngIf="reasonForRejectionFrm.controls['reason'].touched">
                        <div *ngIf="reasonForRejectionFrm.controls['reason'].errors?.required"
                             class="text-danger animated fadeInDown fast-4 small">
                          Dato requerido
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="disableApproval">
                    <div class="col-12">
                      <span>
                        {{ reasonForRejectionFrm.controls['description'].value }}
                      </span>
                    </div>
                  </ng-container>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="modal-footer" *ngIf="!disableApproval">
          <button (click)="actualizarStatusReq(false); modalReject.hide(); " aria-label="Close"
            class="btn btn-danger waves-light btn-md" mdbWavesEffect type="button">
            Cancelar
          </button>
          <button (click)="actualizarStatusReq(true); modalReject.hide();" aria-label="Close"
            [disabled]="!reasonForRejectionFrm.valid"
            class="btn btn btn-indigo darket-3 btn-md" mdbWavesEffect type="button">
            Guardar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>