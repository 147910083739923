import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {Http, ResponseContentType} from '@angular/http';
import {Observable} from 'rxjs';
import { environment } from 'src/environments/environment';

const pathUrlFile = environment.apiBaseUrl+'/api/ven-unica/na/download-file';
const pathUrlFileTmp = environment.apiBaseUrl+'/api/ven-unica/na/download-file-tmp';
const pathUrlVideo = environment.apiBaseUrl+'/api/ven-unica/na/download-video';
const pathUrlVideoGaleria = environment.apiBaseUrl+'/api/empleados/na/download-galeria-video';
//const pathUrlFile = 'http://localhost:8006/na/download-file';
//const pathUrlVideo = 'http://localhost:8006/na/download-video';

@Injectable({ providedIn: 'root' })

export class DownloadFileService {

  constructor(private http: Http) {}

  downloadFile(nombre: string, headers: any): Observable<any> {		
    return this.http.get(pathUrlFile,
    { 
        headers: headers,
        responseType: ResponseContentType.Blob,
        params:
            {id: nombre}
      });
   }

  downloadFileTmp(nombre: string, headers: any): Observable<any> {		
    return this.http.get(pathUrlFileTmp,
    { 
        headers: headers,
        responseType: ResponseContentType.Blob,
        params:
            {id: nombre}
      });
   }

  downloadVideo(nombre: string, headers: any): Observable<any> {		
    return this.http.get(pathUrlVideo,
    { 
        headers: headers,
        responseType: ResponseContentType.Blob,
        params:
            {id: nombre}
      });
   }

  downloadVideoGaleria(nombre: string, headers: any): Observable<any> {
    return this.http.get(pathUrlVideoGaleria,
    {
        headers: headers,
        responseType: ResponseContentType.Blob,
        params:
            {id: nombre}
      });
   }
}